var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "view-contanier" },
    [
      _c(
        "div",
        { staticClass: "opt" },
        [
          _c("div", { staticClass: "tab" }, [
            _c(
              "span",
              {
                directives: [
                  {
                    name: "permissson",
                    rawName: "v-permissson",
                    value: "DSH",
                    expression: "'DSH'",
                  },
                ],
                staticClass: "tabItem",
                class: { active: _vm.active === 1 },
                on: {
                  click: function ($event) {
                    return _vm.changeType(1)
                  },
                },
              },
              [_vm._v("待审核")]
            ),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "permissson",
                    rawName: "v-permissson",
                    value: "YSH",
                    expression: "'YSH'",
                  },
                ],
                staticClass: "tabItem",
                class: { active: _vm.active === 2 },
                on: {
                  click: function ($event) {
                    return _vm.changeType(2)
                  },
                },
              },
              [_vm._v("已审核")]
            ),
          ]),
          _c(
            "BaseFilter",
            {
              attrs: { isActive: _vm.isActive, width: 466 },
              on: { sendFilter: _vm.sendFilter, reset: _vm.reset },
            },
            [
              _c(
                "div",
                { staticClass: "filter" },
                [
                  _c(
                    "el-form",
                    {
                      ref: "form",
                      attrs: { model: _vm.filterList, "label-width": "70px" },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "搜索" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "320px" },
                            attrs: {
                              placeholder: "请输入代码/中文名称/英文名称/简称",
                            },
                            model: {
                              value: _vm.filterList.search,
                              callback: function ($$v) {
                                _vm.$set(_vm.filterList, "search", $$v)
                              },
                              expression: "filterList.search",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "所属区域" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "320px" },
                              attrs: { placeholder: "请选择所属区域" },
                              model: {
                                value: _vm.filterList.areaValue,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filterList, "areaValue", $$v)
                                },
                                expression: "filterList.areaValue",
                              },
                            },
                            _vm._l(_vm.commOptions, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "销售员" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "320px" },
                              attrs: { placeholder: "请选择销售员" },
                              model: {
                                value: _vm.filterList.saleId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filterList, "saleId", $$v)
                                },
                                expression: "filterList.saleId",
                              },
                            },
                            _vm._l(_vm.userOptions, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "状态" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "320px" },
                              attrs: { placeholder: "请选择状态" },
                              model: {
                                value: _vm.filterList.isActived,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filterList, "isActived", $$v)
                                },
                                expression: "filterList.isActived",
                              },
                            },
                            _vm._l(_vm.activeOption, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.active === 1,
              expression: "active === 1",
            },
          ],
          staticClass: "data",
        },
        [
          _vm.height !== 0
            ? _c("BaseTable", {
                attrs: {
                  height: _vm.height,
                  tableData: _vm.tableData,
                  pageIndex: _vm.pagerConfig.pageIndex,
                  pageSize: _vm.pagerConfig.pageSize,
                  loading: _vm.loading,
                },
                on: { handlePageChange: _vm.handlePageChange },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "isActived",
                      fn: function (row) {
                        return [
                          _c("div", {
                            staticClass: "circle",
                            style: {
                              "background-color": row.scope.isActived
                                ? " #35BA83"
                                : "#FE453D",
                            },
                          }),
                          _vm._v(
                            " " +
                              _vm._s(
                                row.scope.isActived ? "使用中" : "未使用"
                              ) +
                              " "
                          ),
                        ]
                      },
                    },
                    {
                      key: "yy",
                      fn: function (row) {
                        return [
                          _c("img", {
                            staticClass: "license",
                            attrs: {
                              src: require("@/assets/images/system/license.png"),
                            },
                            on: {
                              click: function ($event) {
                                return _vm.viewImg(row.scope, 0)
                              },
                            },
                          }),
                        ]
                      },
                    },
                    {
                      key: "userRole",
                      fn: function (row) {
                        return _vm._l(
                          row.scope.userRole,
                          function (item, $index) {
                            return _c(
                              "div",
                              { key: $index },
                              [_c("el-tag", [_vm._v(_vm._s(item.key))])],
                              1
                            )
                          }
                        )
                      },
                    },
                    {
                      key: "opt",
                      fn: function (row) {
                        return [
                          _c(
                            "div",
                            { staticClass: "table-opt" },
                            [
                              _c(
                                "el-button",
                                {
                                  directives: [
                                    {
                                      name: "permissson",
                                      rawName: "v-permissson",
                                      value: "pass",
                                      expression: "'pass'",
                                    },
                                  ],
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.approved(row.scope)
                                    },
                                  },
                                },
                                [_vm._v("通过")]
                              ),
                              _c(
                                "el-button",
                                {
                                  directives: [
                                    {
                                      name: "permissson",
                                      rawName: "v-permissson",
                                      value: "refuse",
                                      expression: "'refuse'",
                                    },
                                  ],
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.refuse(row.scope)
                                    },
                                  },
                                },
                                [_vm._v("拒绝")]
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  651942470
                ),
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.active === 2,
              expression: "active === 2",
            },
          ],
          staticClass: "data",
        },
        [
          _vm.height !== 0
            ? _c("BaseTable", {
                attrs: {
                  height: _vm.height,
                  tableData: _vm.tableData1,
                  pageIndex: _vm.pagerConfig.pageIndex,
                  pageSize: _vm.pagerConfig.pageSize,
                  loading: _vm.loading,
                },
                on: { handlePageChange: _vm.handlePageChange },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "isActived",
                      fn: function (row) {
                        return [
                          _c("div", {
                            staticClass: "circle",
                            style: {
                              "background-color": row.scope.isActived
                                ? " #35BA83"
                                : "#FE453D",
                            },
                          }),
                          _vm._v(
                            " " +
                              _vm._s(
                                row.scope.isActived ? "使用中" : "未使用"
                              ) +
                              " "
                          ),
                        ]
                      },
                    },
                    {
                      key: "yy",
                      fn: function (row) {
                        return [
                          _c("img", {
                            staticClass: "license",
                            attrs: {
                              src: require("@/assets/images/system/license.png"),
                            },
                            on: {
                              click: function ($event) {
                                return _vm.viewImg(row.scope, 1)
                              },
                            },
                          }),
                        ]
                      },
                    },
                    {
                      key: "address",
                      fn: function (row) {
                        return [
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: {
                                effect: "dark",
                                tabindex: 2000,
                                placement: "bottom",
                              },
                            },
                            [
                              _c(
                                "div",
                                { attrs: { slot: "content" }, slot: "content" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("formatterAddress")(
                                          `${row.scope.countryId}/${row.scope.provinceId}/${row.scope.cityId}`
                                        )
                                      ) +
                                      _vm._s(row.scope.address) +
                                      " "
                                  ),
                                ]
                              ),
                              _c("svg-icon", {
                                attrs: {
                                  iconClass: "address",
                                  className: "icon",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                    {
                      key: "remark",
                      fn: function (row) {
                        return [
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: {
                                effect: "dark",
                                tabindex: 2000,
                                content: row.scope.remark,
                                placement: "bottom",
                              },
                            },
                            [
                              _c("svg-icon", {
                                attrs: {
                                  iconClass: "remark",
                                  className: "icon",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                    {
                      key: "opt",
                      fn: function (row) {
                        return [
                          _c(
                            "div",
                            { staticClass: "table-opt" },
                            [
                              _c("el-switch", {
                                directives: [
                                  {
                                    name: "permissson",
                                    rawName: "v-permissson",
                                    value: "able",
                                    expression: "'able'",
                                  },
                                ],
                                attrs: {
                                  "active-color": "#2395F3",
                                  "inactive-color": "#CDCDCD",
                                },
                                on: {
                                  change: (value) => {
                                    _vm.changeStatus(value, row.scope)
                                  },
                                },
                                model: {
                                  value: row.scope.isActived,
                                  callback: function ($$v) {
                                    _vm.$set(row.scope, "isActived", $$v)
                                  },
                                  expression: "row.scope.isActived",
                                },
                              }),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "permissson",
                                      rawName: "v-permissson",
                                      value: "able",
                                      expression: "'able'",
                                    },
                                  ],
                                  staticClass: "status",
                                  style: {
                                    color: row.scope.isActived
                                      ? "#2395F3"
                                      : "#999999",
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      row.scope.isActived ? "启用" : "停用"
                                    )
                                  ),
                                ]
                              ),
                              _c(
                                "el-button",
                                {
                                  directives: [
                                    {
                                      name: "permissson",
                                      rawName: "v-permissson",
                                      value: "update",
                                      expression: "'update'",
                                    },
                                  ],
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.updateRow(row.scope)
                                    },
                                  },
                                },
                                [_vm._v("修改")]
                              ),
                              _c(
                                "el-button",
                                {
                                  directives: [
                                    {
                                      name: "permissson",
                                      rawName: "v-permissson",
                                      value: "priceSetting",
                                      expression: "'priceSetting'",
                                    },
                                  ],
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.billAccount(row.scope)
                                    },
                                  },
                                },
                                [_vm._v("价格设置")]
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  4146175895
                ),
              })
            : _vm._e(),
        ],
        1
      ),
      _c("approved-company-dialog", {
        attrs: {
          dialogVisible: _vm.dialogFormVisible,
          editData: _vm.editData,
          userOptions: _vm.userOptions,
          updateData: _vm.updateData,
        },
        on: { ok: _vm.ok, cancel: _vm.cancel },
      }),
      _c("bill-account-dialog", {
        attrs: {
          dialogVisible: _vm.dialogFormVisible1,
          companyId: _vm.editData.id,
        },
        on: { ok: _vm.ok2, cancel: _vm.cancel2 },
      }),
      _c("RefuseReasonDialog", {
        attrs: { dialogVisible: _vm.dialogFormVisible2 },
        on: {
          ok: _vm.ok3,
          cancel: function ($event) {
            _vm.dialogFormVisible2 = false
          },
        },
      }),
      _c(
        "el-dialog",
        {
          directives: [{ name: "drag", rawName: "v-drag" }],
          attrs: {
            title: "查看营业执照",
            visible: _vm.dialogVisible2,
            "append-to-body": true,
            "close-on-press-escape": false,
            "close-on-click-modal": false,
            "before-close": () => (_vm.dialogVisible2 = false),
            width: "858px",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible2 = $event
            },
          },
        },
        [
          _c("div", { staticClass: "imgbox" }, [
            _c("img", {
              attrs: {
                src: `/backEnd/api/${_vm.config.supplyCode}/file/certification?companyId=${_vm.companyId}`,
              },
            }),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }