<template>
  <el-dialog
    v-drag
    title="分配价格"
    :visible.sync="dialogVisible"
    width="1160px"
    :show-close="true"
    :append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="cancel"
    top="5%"
  >
    <div v-loading="loading" class="content">
      <el-row :gutter="20">
        <el-form ref="form" :model="form" label-position="top">
          <el-col :span="4.8">
            <el-form-item label="模板名称" prop="name">
              <el-input
                v-model="form.name"
                placeholder="请输入"
                style="width: 200px"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4.8">
            <el-form-item label="提单类型" prop="mblType">
              <el-select
                v-model="form.mblType"
                placeholder="请选择"
                style="width: 200px"
              >
                <el-option label="海运" :value="0"></el-option>
                <el-option label="空运" :value="1"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="4.8">
            <el-form-item label="处理方式" prop="pickUpType">
              <el-select
                v-model="form.pickUpType"
                placeholder="请选择"
                style="width: 200px"
              >
                <el-option label="直送" :value="1"></el-option>
                <el-option label="回仓" :value="2"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="4.8">
            <el-form-item label="港口/机场" prop="transportName">
              <el-select
                v-model="form.transportName"
                placeholder="请输入港口/机场"
                style="width: 200px"
                filterable
              >
                <el-option
                  v-for="item in transportOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="4.8">
            <el-button style="margin-top: 30px;" type="primary" @click="screen">筛选</el-button>
            <el-button style="margin-top: 30px;" @click="empty">置空</el-button>
          </el-col>
        </el-form>
      </el-row>
      <el-row>
        <BaseTable
          :tableData="tableData"
          height="440"
          :isPager="false"
          :border="true"
          @handleSelectionChange="handleSelectionChange"
        >
          <template slot="pickUpTypeName" slot-scope="row">
            <div :class="['pickUpType', 'flex_c_c', `pickUpType${row.scope.pickUpType}`]">{{ row.scope.pickUpTypeName }}</div>
          </template>
        </BaseTable>
        <div class="total">已选 <span>{{ selectionItem.length }}</span> 项</div>
      </el-row>
    </div>
    <span slot="footer" class="footers">
      <div class="cancel flex_c_c" @click="cancel">取消</div>
      <div v-loading="loading" class="ok flex_c_c" @click="ok('form')">确定</div>
    </span>
  </el-dialog>
</template>

<script>
const BaseTable = () => import('@/components/table/BaseTable')
import {
  getCompanyMBLPriceTemplate,
  bindingMBLPriceTemplate
} from '@/api/system'
export default {
  components: {
    BaseTable
  },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    companyId: {
      type: [String, Number],
      default: ''
    },
    shipOptions: {
      type: Array,
      default: () => []
    },
    airOptions: {
      type: Array,
      default: () => []
    },
    haveArr: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      form: {
        name: '',
        mblType: '',
        pickUpType: '',
        transportName: ''
      },
      tableData: {
        fileds: [
          {
            filed: 'name',
            label: '模板名称',
            width: 250
          },
          {
            filed: 'mblTypeName',
            label: '提单类型',
            width: 100
          },
          {
            filed: 'pickUpTypeName',
            label: '处理方式',
            width: 100,
            isCustom: true
          },
          {
            filed: 'transportName',
            label: '港口/机场',
            width: 140
          },
          {
            filed: 'effectiveDate',
            label: '生效日期',
            width: 150,
            formatter: (row) =>
              row.effectiveDate
                ? row.effectiveDate.slice(0, row.effectiveDate.length - 9)
                : ''
          },
          {
            filed: 'elapsedDate',
            label: '失效时间',
            width: 150,
            formatter: (row) =>
              row.elapsedDate
                ? row.elapsedDate.slice(0, row.elapsedDate.length - 9)
                : ''
          },
          {
            filed: 'saleId',
            label: '类型',
            width: 120,
            formatter: (row) =>
              row.saleId === '0' || row.saleId === 0 ? '后台模板' : '销售模板'
          }
        ],
        columns: [],
        mutiCheck: true,
        selected: (row) => {
          const date = new Date()
          const today = date.toLocaleDateString(
            'zh-CN', {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit'
            }
          )
          const newDay = Date.parse(today.split('/').join('-'))
          const elapsedDate = Date.parse(row.elapsedDate.slice(0, row.elapsedDate.length - 9))
          let flag = true
          this.haveArr.forEach(a => {
            const haveElapsedDate = Date.parse(a.elapsedDate.slice(0, a.elapsedDate.length - 9))
            if (a.mblPriceTemplateId === row.id) {
              flag = false
            }
            if (haveElapsedDate <= newDay) {
              flag = true
            }
          })
          return elapsedDate > newDay && flag
        }
      },
      loading: false,
      selectionItem: [],
      columns: []
    }
  },
  computed: {
    transportOptions() {
      return [...this.shipOptions, ...this.airOptions]
    }
  },
  watch: {
    dialogVisible(val) {
      val && this.getList()
    }
  },
  methods: {
    cancel() {
      this.$refs['form'].resetFields()
      this.$emit('cancel')
    },
    ok(formName) {
      this.$refs[formName].validate(async(valid) => {
        if (valid) {
          if (!this.selectionItem.length) {
            this.$message.error('列表至少需要勾选一条')
            return
          }
          this.loading = true
          try {
            const templateIds = this.selectionItem.map(a => a.id)
            const params = {
              templateIds,
              companyId: this.companyId
            }
            const res = await bindingMBLPriceTemplate(params)
            this.loading = false
            if (res.success) {
              this.$message.success(res.errorMessage)
              this.$emit('ok')
              this.$refs['form'].resetFields()
            } else {
              this.$message.error(res.errorMessage)
            }
          } catch (error) {
            this.loading = false
            console.error(error)
          }
        } else {
          return false
        }
      })
    },
    async getList() {
      try {
        const res = await getCompanyMBLPriceTemplate({ companyId: this.companyId })
        if (res.success) {
          this.tableData.columns = res.data
          this.columns = res.data
        } else {
          this.$message.error(res.errorMessage)
        }
      } catch (error) {
        console.error(error)
      }
    },
    handleSelectionChange(items) {
      this.selectionItem = items
    },
    screen() {
      const { name, mblType, pickUpType, transportName } = this.form
      if (name !== '' || mblType !== '' || pickUpType !== '' || transportName !== '') {
        const arr = []
        this.columns.forEach(a => {
          if (a.name === name || a.mblType === mblType || a.pickUpType === pickUpType || a.transportName === transportName) {
            arr.push(a)
          }
        })
        this.tableData.columns = arr
      } else {
        this.tableData.columns = this.columns
      }
    },
    empty() {
      this.form = {
        name: '',
        mblType: '',
        pickUpType: '',
        transportName: ''
      }
      this.tableData.columns = this.columns
    }
  }
}
</script>

<style lang="less" scoped>
.content {
  width: calc(100% - 40px);
  margin: 0 auto;
  .pickUpType {
    width: 40px;
    height: 26px;
    border-radius: 4px 4px 4px 4px;
    font-size: 12px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    margin: 0 auto;
  }
  .pickUpType1 {
    background: rgba(35, 149, 243, .2);
    color: #2395F3;
  }
  .pickUpType2 {
    background: rgba(53, 186, 131, .2);
    color: #35BA83;
  }
  .total {
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    margin-top: 14px;
    span {
      color: #2395F3;
    }
  }
}
.footers {
  width: 100%;
  display: flex;
  justify-content: center;
  & div {
    width: 120px;
    height: 40px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    border-radius: 4px 4px 4px 4px;
    cursor: pointer;
  }
  .cancel {
    background: #eeeeee;
    color: #999999;
    margin-right: 16px;
  }
  .ok {
    background: #2395f3;
    color: #ffffff;
  }
}
</style>
