<template>
  <el-dialog
    v-drag
    title="新增"
    :visible.sync="dialogVisible"
    width="500px"
    :show-close="true"
    :append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="cancel"
  >
    <div class="content">
      <el-form ref="form" class="form" :model="form" :rules="rules" label-width="66px">
        <el-form-item prop="nameCH" label="中文名">
          <el-input v-model="form.nameCH" placeholder="请输入中文名"></el-input>
        </el-form-item>
        <el-form-item prop="nameEN" label="英文名">
          <el-input v-model="form.nameEN" placeholder="请输入英文名"></el-input>
        </el-form-item>
        <el-form-item prop="type" label="类型">
          <el-select
            v-model="form.type"
            style="width: 100%"
            placeholder="请选择"
          >
            <el-option
              v-for="item in typeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="phone" label="手机">
          <el-input v-model="form.phone" placeholder="请输入手机"></el-input>
        </el-form-item>
        <el-form-item prop="tel" label="电话">
          <el-input v-model="form.tel" placeholder="请输入电话"></el-input>
        </el-form-item>
        <el-form-item prop="email" label="邮箱">
          <el-input v-model="form.email" placeholder="请输入邮箱"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="footers">
      <div class="cancel" @click="cancel">取 消</div>
      <div class="ok" @click="ok('form')">确 定</div>
    </span>
  </el-dialog>
</template>

<script>
import { isValidPhone, isValidEmail } from '@/utils/validate'
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    const validatePhone = (rule, value, callback) => {
      if (!isValidPhone(value)) {
        callback(new Error('请输入正确的手机号码'))
      } else {
        callback()
      }
    }
    const validateEmail = (rule, value, callback) => {
      if (!isValidEmail(value)) {
        callback(new Error('请输入正确的邮箱'))
      } else {
        callback()
      }
    }
    return {
      form: {
        nameCH: '',
        nameEN: '',
        type: 0,
        phone: '',
        tel: '',
        email: ''
      },
      rules: {
        nameCH: [{ required: true, message: '请输入中文名', trigger: 'blur' }],
        type: [{ required: true, message: '请输入中文名', trigger: 'blur' }],
        phone: [{ required: true, message: '请输入手机', trigger: 'blur' }, { validator: validatePhone, trigger: 'blur' }],
        email: [{ required: true, message: '请输入邮箱', trigger: 'blur' }, { validator: validateEmail, trigger: 'blur' }]
      },
      typeOptions: [
        {
          value: 0,
          label: '操作'
        },
        {
          value: 1,
          label: '财务'
        },
        {
          value: 2,
          label: '客服'
        },
        {
          value: 3,
          label: '负责人'
        }
      ]
    }
  },
  methods: {
    cancel() {
      this.$refs['form']?.resetFields()
      this.$emit('cancel')
    },
    ok(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$emit('ok', this.form)
          this.$refs[formName]?.resetFields()
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.footers {
  display: flex;
  justify-content: center;
  .cancel {
    width: 120px;
    height: 40px;
    background: #eeeeee;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    font-size: 14px;
    font-weight: 400;
    color: #999999;
    line-height: 40px;
    text-align: center;
    cursor: pointer;
  }
  .ok {
    width: 120px;
    height: 40px;
    background: #2395f3;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    line-height: 40px;
    text-align: center;
    margin-left: 20px;
    cursor: pointer;
  }
}
</style>
