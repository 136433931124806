var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      directives: [{ name: "drag", rawName: "v-drag" }],
      attrs: {
        title: "拒绝原因",
        visible: _vm.dialogVisible,
        width: "500px",
        "show-close": true,
        "append-to-body": true,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "before-close": _vm.cancel,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "content" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              staticClass: "form",
              attrs: { model: _vm.form, rules: _vm.rules },
            },
            [
              _c(
                "el-form-item",
                { attrs: { prop: "refuseReason", label: "拒绝原因" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入拒绝原因" },
                    model: {
                      value: _vm.form.refuseReason,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "refuseReason", $$v)
                      },
                      expression: "form.refuseReason",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        { staticClass: "footers", attrs: { slot: "footer" }, slot: "footer" },
        [
          _c("div", { staticClass: "cancel", on: { click: _vm.cancel } }, [
            _vm._v("取 消"),
          ]),
          _c(
            "div",
            {
              staticClass: "ok",
              on: {
                click: function ($event) {
                  return _vm.ok("form")
                },
              },
            },
            [_vm._v("确 定")]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }