<template>
  <el-dialog
    v-drag
    title="价格设置"
    :visible.sync="dialogVisible"
    width="1160px"
    :show-close="true"
    :append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="cancel"
  >
    <div class="content">
      <div class="tab">
        <BaseTab
          style="z-index: 1"
          :tabItems="tabItems"
          :propWidth="490"
          :active="active"
          @tabChange="tabChange"
        ></BaseTab>
      </div>
      <div class="add">
        <div v-if="!active" class="item flex_c_c" @click="add">
          <i class="el-icon-circle-plus-outline" />
          <span>新增</span>
        </div>
        <div v-else class="price flex_c_c" @click="dialogVisible2 = true">
          <img src="@/assets/images/system/distribution.png" />
          分配价格
        </div>
      </div>
      <template v-if="!active">
        <BaseTable
          :tableData="tableData"
          :height="440"
          :isPager="false"
          :showTooltip="false"
          :border="true"
          @dblclick="dblclick"
        >
          <template slot="opt" slot-scope="row">
            <div class="table-opt">
              <el-switch
                v-model="row.scope.caeActived"
                :active-text="row.scope.caeActived ? '启用' : '停用'"
                @change="onChange(row.scope, row.scope.caeActived)"
              >
              </el-switch>
              <!-- <i class="el-icon-edit-outline icon" @click="updateRow(row.scope)" /> -->
              <i class="el-icon-delete icon" @click="deleteRow(row.scope)" />
            </div>
          </template>
          <template slot="isActived" slot-scope="row">
            <div class="flex_c_c">
              <i :class="['status', row.scope.isActived ? 'green' : 'red']"></i>
              {{ row.scope.isActived ? "启用" : "停用" }}
            </div>
          </template>
          <template slot="deliveryRate" slot-scope="row">
            <template v-if="!row.scope.isEdit">
              {{ row.scope.deliveryRate }}
            </template>
            <template v-else>
              <el-input
                v-model="row.scope.deliveryRate"
                @focus="lastDR = row.scope.deliveryRate"
                @blur="onBlur(row.scope)"
              ></el-input>
            </template>
          </template>
          <template slot="deliveryRate-header">
            <el-tooltip
              class="item"
              effect="dark"
              content="双击派送折扣列可编辑"
              placement="top"
            >
              <img
                src="@/assets/images/system/edit.png"
                style="margin-left: 5px"
              />
            </el-tooltip>
          </template>
        </BaseTable>
      </template>
      <template v-else>
        <div class="active_title">价格模板</div>
        <BaseTable
          :tableData="tableData1"
          :height="440"
          :isPager="false"
          :showTooltip="false"
          :border="true"
        >
          <template slot="name" slot-scope="row">
            {{ row.scope.name }}
            <img v-if="row.scope.disabled" class="outDate" src="@/assets/images/system/outDate.png">
          </template>
          <template slot="pickUpTypeName" slot-scope="row">
            <div :class="['pickUpType', 'flex_c_c', `pickUpType${row.scope.pickUpType}`]">{{ row.scope.pickUpTypeName }}</div>
          </template>
          <template slot="opt" slot-scope="row">
            <el-button
              type="text"
              size="small"
              @click="detailRow(row.scope)"
            >详情
            </el-button>
            <el-button
              type="text"
              size="small"
              :disabled="row.scope.disabled"
              @click="expire(row.scope)"
            >过期
            </el-button>
          </template>
        </BaseTable>
      </template>
    </div>
    <span slot="footer" class="footers">
      <div class="cancel" @click="cancel">取 消</div>
      <div class="ok" @click="ok('form')">确 定</div>
    </span>
    <add-bill-account-dialog
      :dialogVisible="dialogVisible1"
      :editData="editData"
      :accountNumArr="accountNumArr"
      :companyId="companyId"
      @ok="ok2"
      @cancel="cancel2"
    ></add-bill-account-dialog>
    <DistributionPriceDialog
      :dialogVisible="dialogVisible2"
      :companyId="companyId"
      :shipOptions="shipOptions"
      :airOptions="airOptions"
      :haveArr="tableData1.columns"
      @cancel="dialogVisible2 = false"
      @ok="ok3"
    ></DistributionPriceDialog>
    <PriceTemplateDetailDialog
      :dialogVisible="dialogVisible3"
      :rowData="rowData"
      :feeTypeOptions="feeTypeOptions"
      :feeTypeOptions2="feeTypeOptions2"
      @cancel="dialogVisible3 = false"
    ></PriceTemplateDetailDialog>
  </el-dialog>
</template>

<script>
const BaseTable = () => import('@/components/table/BaseTable')
const BaseTab = () => import('@/components/tab/BaseTab')
import {
  getCEA,
  deleteCEA,
  updateCEA,
  switchCompanyExpressAccount,
  getCompanyMBLPriceList,
  expireMBLPriceTemplate
} from '@/api/system'
import { getFeeTypeList, getFeeNamePageList } from '@/api/fee'
import AddBillAccountDialog from '../components/AddBillAccountDialog'
import DistributionPriceDialog from '../components/DistributionPriceDialog'
import PriceTemplateDetailDialog from '../components/PriceTemplateDetailDialog'

//   getCEA,
//   addCEA,
//   updateCEA,
//   deleteCEA,
export default {
  components: {
    BaseTable,
    AddBillAccountDialog,
    BaseTab,
    DistributionPriceDialog,
    PriceTemplateDetailDialog
  },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    companyId: {
      type: [String, Number],
      default: ''
    }
  },
  data() {
    return {
      tableData: {
        fileds: [
          {
            filed: 'expressSupplierName',
            label: '快递服务商',
            width: 120,
            resizable: true
          },
          {
            filed: 'expressSupplierServiceName',
            label: '服务类型',
            width: 166,
            resizable: true
          },
          {
            filed: 'accountNumber',
            label: '账号',
            width: 166,
            resizable: true
          },
          {
            filed: 'deliveryRate',
            label: '派送折扣',
            width: 120,
            isCustom: true,
            resizable: true
          },
          {
            filed: 'isActived',
            label: '账号状态',
            width: '100',
            isCustom: true,
            resizable: true
          },
          {
            filed: 'usageRate',
            label: '账号使用费',
            width: 100,
            resizable: true
          },
          {
            filed: 'expressAccountTemplateName',
            label: '模板来源',
            resizable: true
          },
          {
            filed: 'opt',
            label: '操作',
            width: '160',
            isCustom: true,
            resizable: true
          }
        ],
        columns: []
      },
      tableData1: {
        fileds: [
          {
            filed: 'name',
            label: '模板名称',
            width: 260,
            resizable: true,
            isCustom: true
          },
          {
            filed: 'mblTypeName',
            label: '提单类型',
            width: 100,
            resizable: true
          },
          {
            filed: 'pickUpTypeName',
            label: '处理方式',
            width: 100,
            resizable: true,
            isCustom: true
          },
          {
            filed: 'transportName',
            label: '港口/机场',
            width: 140,
            resizable: true
          },
          {
            filed: 'effectiveDate',
            label: '生效日期',
            width: 120,
            resizable: true,
            formatter: (row) =>
              row.effectiveDate
                ? row.effectiveDate.slice(0, row.effectiveDate.length - 9)
                : ''
          },
          {
            filed: 'elapsedDate',
            label: '失效日期',
            width: 120,
            resizable: true,
            formatter: (row) =>
              row.elapsedDate
                ? row.elapsedDate.slice(0, row.elapsedDate.length - 9)
                : ''
          },
          {
            filed: 'saleId',
            label: '类型',
            width: 120,
            resizable: true,
            formatter: (row) =>
              row.saleId === '0' || row.saleId === 0 ? '后台模板' : '销售模板'
          },
          {
            filed: 'opt',
            label: '操作',
            resizable: true,
            isCustom: true
          }
        ],
        columns: [],
        tableRowClassName({ row }) {
          if (row.disabled) {
            return 'error-row'
          }
        }
      },
      dialogVisible1: false,
      dialogVisible2: false,
      dialogVisible3: false,
      accountNumArr: [],
      editData: {},
      lastDR: null,
      active: 0,
      tabItems: [
        {
          id: 0,
          name: '制单账号'
        },
        {
          id: 1,
          name: '提单报价'
        }
      ],
      shipOptions: [],
      airOptions: [],
      feeTypeOptions: [],
      feeTypeOptions2: [],
      rowData: {}
    }
  },
  watch: {
    dialogVisible(val) {
      if (val) {
        this.getList()
      }
    }
  },
  methods: {
    tabChange(type) {
      if (type.id) {
        this.getCompanyMBLPriceList()
        this.getTransportOptions()
        this.getFeeTypeList()
      }
      this.active = type.id
    },
    cancel() {
      this.active = 0
      this.$emit('cancel')
    },
    async getList() {
      try {
        const res = await getCEA({ companyId: this.companyId })
        if (res.success) {
          this.tableData.columns = res.data.map((a) => {
            return {
              ...a,
              isEdit: false
            }
          })
          this.accountNumArr = res.data.map((a) => a.accountNumber)
        } else {
          this.$message.error(res.errorMessage)
        }
      } catch (error) {
        console.log(error)
      }
    },
    async getTransportOptions() {
      const arr1 = await this.$store.dispatch('getHarborDrapDown', {
        portType: 0,
        type: 1
      })
      const arr2 = await this.$store.dispatch('getHarborDrapDown', {
        portType: 1,
        type: 1
      })
      this.shipOptions = arr2
      this.airOptions = arr1
    },
    async getCompanyMBLPriceList() {
      try {
        const res = await getCompanyMBLPriceList({ companyId: this.companyId })
        if (res.success) {
          const date = new Date()
          const today = date.toLocaleDateString(
            'zh-CN', {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit'
            }
          )
          const newDay = Date.parse(today.split('/').join('-'))
          this.tableData1.columns = res.data.map(a => {
            const elapsedDate = Date.parse(a.elapsedDate.slice(0, a.elapsedDate.length - 9))
            let disabled = false
            if (elapsedDate <= newDay) {
              disabled = true
            }
            return {
              ...a,
              disabled
            }
          })
        } else {
          this.$message.error(res.errorMessage)
        }
      } catch (error) {
        console.error(error)
      }
    },
    async getFeeTypeList() {
      try {
        const res = await getFeeTypeList()
        if (res.success) {
          const arr = []
          res.data &&
            res.data.forEach((item) => {
              if (item.id === 11 || item.id === 13) {
                arr.push({
                  value: item.id,
                  label: item.name
                })
              }
            })
          this.feeTypeOptions = arr
          const promise = res.data.map(async(item) => {
            const params = {
              pageIndex: 1,
              pageSize: 1000,
              queryParam: {
                feeTypeId: item.id,
                search: '',
                isActived: '',
                isVisible: true
              }
            }
            let result
            try {
              result = await getFeeNamePageList(params)
            } catch (error) {
              console.error(error)
            }
            return new Promise((resolve) => {
              resolve(result)
            })
          })
          Promise.all(promise).then((res) => {
            const arr = []
            res.forEach((item) => {
              item.data.list.forEach((dataItem) => {
                if (dataItem.unit !== null) {
                  arr.push({
                    ...dataItem,
                    value: dataItem.id,
                    label: dataItem.name
                  })
                }
              })
            })
            this.feeTypeOptions2 = arr
          })
        } else {
          this.$message.error(res.errorMessage)
        }
      } catch (error) {
        console.error(error)
      }
    },
    ok() {
      this.cancel()
    },
    add() {
      this.dialogVisible1 = true
    },
    ok2() {
      this.getList()
      this.cancel2()
    },
    ok3() {
      this.dialogVisible2 = false
      this.getCompanyMBLPriceList()
    },
    cancel2() {
      this.dialogVisible1 = false
      this.editData = {}
    },
    updateRow(row) {
      this.editData = row
      this.dialogVisible1 = true
    },
    deleteRow(row) {
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        iconClass: 'warning',
        showClose: false,
        closeOnPressEscape: false,
        closeOnClickModal: false,
        center: true
      })
        .then(() => {
          deleteCEA({
            id: row.id
          }).then((res) => {
            if (res.success) {
              this.$message.success('删除成功')
              this.getList()
            } else {
              this.$message.error(res.errorMessage)
            }
          })
        })
        .catch(() => {})
    },
    async onChange(params, type) {
      try {
        const res = await switchCompanyExpressAccount(
          [params.id],
          type ? 1 : 0
        )
        if (res.success) {
          this.$message.success(res.errorMessage)
        } else {
          this.$message.error(res.errorMessage)
        }
      } catch (error) {
        console.error(error)
      }
    },
    dblclick(row, column) {
      if (column.label !== '派送折扣') return
      row.isEdit = true
    },
    async onBlur(row) {
      row.isEdit = false
      const { id, deliveryRate } = row
      if (this.lastDR === deliveryRate) return
      const params = {
        id,
        deliveryRate
      }
      try {
        const res = await updateCEA(params)
        if (res.success) {
          this.lastDR = null
          this.$message.success('修改成功')
        } else {
          this.$message.error(res.errorMessage)
        }
      } catch (error) {
        console.error(error)
      }
    },
    async expire(row) {
      try {
        const res = await expireMBLPriceTemplate({ id: row.id })
        if (res.success) {
          this.getCompanyMBLPriceList()
          this.$message.success(res.errorMessage)
        } else {
          this.$message.error(res.errorMessage)
        }
      } catch (error) {
        console.error(error)
      }
    },
    detailRow(row) {
      this.rowData = row
      this.dialogVisible3 = true
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .fTable .el-table {
  border-radius: 4px 4px 4px 4px;
  overflow: hidden;
  .el-table__cell {
    height: 40px;
    padding: 0;
    input {
      border: 0;
    }
  }
}
.content {
  position: relative;
  width: calc(100% - 40px);
  margin: 0 auto;
  .tab {
    position: absolute;
    top: -40px;
    left: 50%;
    transform: translateX(-50%);
  }
}
.add {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  overflow: hidden;
  & div {
    width: 100px;
    height: 40px;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    background: #35ba83;
    cursor: pointer;
  }
  .item {
    margin-bottom: 20px;
    span {
      margin-left: 5px;
    }
  }
}
.active_title {
  margin-bottom: 10px;
}
.icon {
  font-size: 20px;
  color: #2395f3;
  cursor: pointer;
}
.status {
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin-right: 5px;
  &.green {
    background: #35ba83;
  }
  &.red {
    background: red;
  }
}
.outDate {
  position: absolute;
  top: 0;
  left: 0;
}
/deep/ .el-table .error-row {
  background: #FFF4F3;
}
.table-opt {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.pickUpType {
  width: 40px;
  height: 26px;
  border-radius: 4px 4px 4px 4px;
  font-size: 12px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  margin: 0 auto;
}
.pickUpType1 {
  background: rgba(35, 149, 243, .2);
  color: #2395F3;
}
.pickUpType2 {
  background: rgba(53, 186, 131, .2);
  color: #35BA83;
}
.footers {
  display: flex;
  justify-content: center;
  .cancel {
    width: 120px;
    height: 40px;
    background: #eeeeee;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    font-size: 14px;
    font-weight: 400;
    color: #999999;
    line-height: 40px;
    text-align: center;
    cursor: pointer;
  }
  .ok {
    width: 120px;
    height: 40px;
    background: #2395f3;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    line-height: 40px;
    text-align: center;
    margin-left: 20px;
    cursor: pointer;
  }
}
</style>
