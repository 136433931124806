var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      directives: [{ name: "drag", rawName: "v-drag" }],
      attrs: {
        title: _vm.editData.code ? "修改" : "审核",
        visible: _vm.dialogVisible,
        width: "1160px",
        top: "1%",
        "show-close": true,
        "append-to-body": true,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "before-close": _vm.cancel,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "content",
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              staticClass: "form",
              attrs: { model: _vm.companyData, rules: _vm.rules },
            },
            [
              _c("el-row", { staticClass: "row" }, [
                _c("div", { staticClass: "panel" }, [
                  _c(
                    "div",
                    { staticClass: "form-item w-200" },
                    [
                      _c("label", { staticClass: "lable" }, [
                        _vm._v("客户编码"),
                      ]),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "nameCH" } },
                        [
                          _c("el-input", {
                            attrs: {
                              disabled: "",
                              placeholder: "请输入中文名",
                            },
                            model: {
                              value: _vm.companyData.nameCH,
                              callback: function ($$v) {
                                _vm.$set(_vm.companyData, "nameCH", $$v)
                              },
                              expression: "companyData.nameCH",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "form-item w-420" },
                    [
                      _c("label", { staticClass: "lable" }, [
                        _vm._v("中文名称"),
                      ]),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "nameCH" } },
                        [
                          _c("el-input", {
                            attrs: {
                              disabled: "",
                              placeholder: "请输入中文名",
                            },
                            model: {
                              value: _vm.companyData.nameCH,
                              callback: function ($$v) {
                                _vm.$set(_vm.companyData, "nameCH", $$v)
                              },
                              expression: "companyData.nameCH",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "form-item w-420" },
                    [
                      _c("label", { staticClass: "lable" }, [
                        _vm._v("英文名称"),
                      ]),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "nameEN" } },
                        [
                          _c("el-input", {
                            attrs: {
                              disabled: !!_vm.editData.code,
                              placeholder: "请输入英文名",
                            },
                            model: {
                              value: _vm.companyData.nameEN,
                              callback: function ($$v) {
                                _vm.$set(_vm.companyData, "nameEN", $$v)
                              },
                              expression: "companyData.nameEN",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
              _c("el-row", [
                _c("div", { staticClass: "panel" }, [
                  _c(
                    "div",
                    { staticClass: "form-item w-200" },
                    [
                      _c("label", { staticClass: "lable" }, [_vm._v("地址")]),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "addressStr" } },
                        [
                          _c("el-input", {
                            attrs: { disabled: "", placeholder: "请输入地址" },
                            model: {
                              value: _vm.companyData.addressStr,
                              callback: function ($$v) {
                                _vm.$set(_vm.companyData, "addressStr", $$v)
                              },
                              expression: "companyData.addressStr",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "form-item w-420" },
                    [
                      _c("label", { staticClass: "lable" }, [
                        _vm._v("详细地址"),
                      ]),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "nameCH" } },
                        [
                          _c("el-input", {
                            attrs: {
                              disabled: "",
                              placeholder: "请输入详细地址",
                            },
                            model: {
                              value: _vm.companyData.address,
                              callback: function ($$v) {
                                _vm.$set(_vm.companyData, "address", $$v)
                              },
                              expression: "companyData.address",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "form-item w-200" },
                    [
                      _c("label", { staticClass: "lable" }, [
                        _c("span", { staticClass: "red" }, [_vm._v("* ")]),
                        _vm._v("代码"),
                      ]),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "code" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入代码(四-五位唯一)" },
                            model: {
                              value: _vm.companyData.code,
                              callback: function ($$v) {
                                _vm.$set(_vm.companyData, "code", $$v)
                              },
                              expression: "companyData.code",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "form-item w-200" },
                    [
                      _c("label", { staticClass: "lable" }, [
                        _c("span", { staticClass: "red" }, [_vm._v("* ")]),
                        _vm._v("简称"),
                      ]),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "wbName" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入简称" },
                            model: {
                              value: _vm.companyData.wbName,
                              callback: function ($$v) {
                                _vm.$set(_vm.companyData, "wbName", $$v)
                              },
                              expression: "companyData.wbName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
              _c("el-row", [
                _c("div", { staticClass: "panel" }, [
                  _c(
                    "div",
                    { staticClass: "form-item w-200" },
                    [
                      _c("label", { staticClass: "lable" }, [
                        _c("span", { staticClass: "red" }, [_vm._v("* ")]),
                        _vm._v("状态"),
                      ]),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "isActived" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "请选择" },
                              model: {
                                value: _vm.companyData.isActived,
                                callback: function ($$v) {
                                  _vm.$set(_vm.companyData, "isActived", $$v)
                                },
                                expression: "companyData.isActived",
                              },
                            },
                            _vm._l(_vm.activeOptions, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "form-item w-200" },
                    [
                      _c("label", { staticClass: "lable" }, [
                        _c("span", { staticClass: "red" }, [_vm._v("* ")]),
                        _vm._v("销售员"),
                      ]),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "saleId" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "请选择销售员" },
                              model: {
                                value: _vm.companyData.saleId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.companyData, "saleId", $$v)
                                },
                                expression: "companyData.saleId",
                              },
                            },
                            _vm._l(_vm.userOptions, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "form-item w-200" },
                    [
                      _c("label", { staticClass: "lable" }, [
                        _c("span", { staticClass: "red" }, [_vm._v("* ")]),
                        _vm._v("预警邮箱"),
                      ]),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "noticeEmail" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入预警邮箱" },
                            model: {
                              value: _vm.companyData.noticeEmail,
                              callback: function ($$v) {
                                _vm.$set(_vm.companyData, "noticeEmail", $$v)
                              },
                              expression: "companyData.noticeEmail",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "form-item w-200" },
                    [
                      _c("label", { staticClass: "lable" }, [
                        _c("span", { staticClass: "red" }, [_vm._v("* ")]),
                        _vm._v("结算币种"),
                      ]),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "currency" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                placeholder: "请选择",
                                disabled: !!_vm.editData.code,
                              },
                              model: {
                                value: _vm.companyData.currency,
                                callback: function ($$v) {
                                  _vm.$set(_vm.companyData, "currency", $$v)
                                },
                                expression: "companyData.currency",
                              },
                            },
                            _vm._l(_vm.currencyData, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "form-item w-200" },
                    [
                      _c("label", { staticClass: "lable" }, [
                        _c("span", { staticClass: "red" }, [_vm._v("* ")]),
                        _vm._v("签订合同"),
                      ]),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "bussinessFlag" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "请选择" },
                              model: {
                                value: _vm.companyData.bussinessFlag,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.companyData,
                                    "bussinessFlag",
                                    $$v
                                  )
                                },
                                expression: "companyData.bussinessFlag",
                              },
                            },
                            _vm._l(_vm.bussinessFlagOptions, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
              _c("el-row", [
                _c("div", { staticClass: "panel" }, [
                  _c(
                    "div",
                    {
                      staticClass: "form-item",
                      staticStyle: { width: "100%" },
                    },
                    [
                      _c("label", { staticClass: "lable" }, [
                        _c("span", { staticClass: "red" }, [_vm._v("* ")]),
                        _vm._v("所属区域"),
                      ]),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "areaValue" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "460px" },
                              attrs: { placeholder: "请选择" },
                              model: {
                                value: _vm.companyData.areaValue,
                                callback: function ($$v) {
                                  _vm.$set(_vm.companyData, "areaValue", $$v)
                                },
                                expression: "companyData.areaValue",
                              },
                            },
                            _vm._l(_vm.commOptions, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                          _c(
                            "span",
                            { staticClass: "add", on: { click: _vm.addComm } },
                            [_vm._v("管理区域")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "form-item",
                      staticStyle: { width: "100%" },
                    },
                    [
                      _c("label", { staticClass: "lable" }, [_vm._v("备注")]),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "remark" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入备注" },
                            model: {
                              value: _vm.companyData.remark,
                              callback: function ($$v) {
                                _vm.$set(_vm.companyData, "remark", $$v)
                              },
                              expression: "companyData.remark",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
              _c("el-row", [
                _c("div", { staticClass: "panel" }, [
                  _c(
                    "div",
                    {
                      staticClass: "form-item",
                      staticStyle: { width: "100%" },
                    },
                    [
                      _c("label", { staticClass: "lable" }, [
                        _vm._v("T6后台地址"),
                      ]),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "areaValue" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "530px" },
                            attrs: { placeholder: "请输入T6后台地址" },
                            model: {
                              value: _vm.companyData.t6Url,
                              callback: function ($$v) {
                                _vm.$set(_vm.companyData, "t6Url", $$v)
                              },
                              expression: "companyData.t6Url",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "form-item",
                      staticStyle: { width: "100%" },
                    },
                    [
                      _c("label", { staticClass: "lable" }, [_vm._v("T6密钥")]),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "remark" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "530px" },
                            attrs: { placeholder: "请输入T6密钥" },
                            model: {
                              value: _vm.companyData.t6Token,
                              callback: function ($$v) {
                                _vm.$set(_vm.companyData, "t6Token", $$v)
                              },
                              expression: "companyData.t6Token",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "tab" },
            [
              _c("div", { staticClass: "line" }),
              _c("BaseTab", {
                staticStyle: { "z-index": "1" },
                attrs: {
                  tabItems: _vm.tabItems,
                  propWidth: 490,
                  active: _vm.active,
                },
                on: { tabChange: _vm.tabChange },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "add-item-content" }, [
            _c("div", { staticClass: "add-item", on: { click: _vm.add } }, [
              _c("i", { staticClass: "el-icon-circle-plus-outline" }),
              _vm._v(" 添加 "),
            ]),
          ]),
          _c("div", { staticClass: "baseTable" }, [
            _c(
              "ul",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.active === 0,
                    expression: "active === 0",
                  },
                ],
              },
              [
                _c("li", { staticClass: "header" }, [
                  _c("div", { staticClass: "cell" }, [
                    _c("span", { staticClass: "red" }, [_vm._v("* ")]),
                    _vm._v("中文名"),
                  ]),
                  _c("div", { staticClass: "cell" }, [_vm._v("英文名")]),
                  _c("div", { staticClass: "cell" }, [
                    _c("span", { staticClass: "red" }, [_vm._v("* ")]),
                    _vm._v("联系人类型"),
                  ]),
                  _c("div", { staticClass: "cell" }, [
                    _c("span", { staticClass: "red" }, [_vm._v("* ")]),
                    _vm._v("手机"),
                  ]),
                  _c("div", { staticClass: "cell" }, [
                    _c("span", { staticClass: "red" }, [_vm._v("* ")]),
                    _vm._v("邮箱"),
                  ]),
                  _c("div", { staticClass: "cell" }, [_vm._v("固定电话")]),
                  _c("div", { staticClass: "cell" }, [_vm._v("操作")]),
                ]),
              ]
            ),
            _c(
              "ul",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.active === 0,
                    expression: "active === 0",
                  },
                ],
                staticClass: "tableBody",
              },
              [
                _vm._l(_vm.contactList, function (item, $index) {
                  return [
                    [
                      _c("li", { key: $index, staticClass: "row" }, [
                        _c(
                          "div",
                          {
                            staticClass: "cell",
                            attrs: { title: item.nameCH },
                          },
                          [_vm._v(_vm._s(item.nameCH))]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "cell",
                            attrs: { title: item.nameEN },
                          },
                          [_vm._v(_vm._s(item.nameEN))]
                        ),
                        _c("div", { staticClass: "cell" }, [
                          _vm._v(_vm._s(_vm._f("formatterType")(item.type))),
                        ]),
                        _c(
                          "div",
                          { staticClass: "cell", attrs: { title: item.phone } },
                          [_vm._v(_vm._s(item.phone))]
                        ),
                        _c(
                          "div",
                          { staticClass: "cell", attrs: { title: item.email } },
                          [_vm._v(_vm._s(item.email))]
                        ),
                        _c(
                          "div",
                          { staticClass: "cell", attrs: { title: item.tel } },
                          [_vm._v(_vm._s(item.tel))]
                        ),
                        _c("div", { staticClass: "cell" }, [
                          _c("i", {
                            staticClass: "el-icon-delete",
                            attrs: { title: "删除" },
                            on: {
                              click: function ($event) {
                                return _vm.removeContactList($index)
                              },
                            },
                          }),
                        ]),
                      ]),
                    ],
                  ]
                }),
              ],
              2
            ),
            _c(
              "ul",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.active === 1,
                    expression: "active === 1",
                  },
                ],
              },
              [
                _c("li", { staticClass: "header" }, [
                  _c("div", { staticClass: "cell1" }, [
                    _c("span", { staticClass: "red" }, [_vm._v("* ")]),
                    _vm._v("跟进人类型"),
                  ]),
                  _c("div", { staticClass: "cell1" }, [
                    _c("span", { staticClass: "red" }, [_vm._v("* ")]),
                    _vm._v("用户"),
                  ]),
                  _c("div", { staticClass: "cell1" }, [
                    _c("span", { staticClass: "red" }, [_vm._v("* ")]),
                    _vm._v("中文名"),
                  ]),
                ]),
              ]
            ),
            _c(
              "ul",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.active === 1,
                    expression: "active === 1",
                  },
                ],
                staticClass: "tableBody",
              },
              [
                _vm._l(_vm.followUpsList, function (item, $index) {
                  return [
                    [
                      _c("li", { key: $index, staticClass: "row" }, [
                        _c("div", { staticClass: "cell1" }, [
                          _vm._v(_vm._s(_vm._f("formatterType")(item.type))),
                        ]),
                        _c("div", { staticClass: "cell1" }, [
                          _vm._v(_vm._s(item.nameCH)),
                        ]),
                        _c("div", { staticClass: "cell1" }, [
                          _c("i", {
                            staticClass: "el-icon-delete",
                            attrs: { title: "删除" },
                            on: {
                              click: function ($event) {
                                return _vm.removeFollowUpsList($index)
                              },
                            },
                          }),
                        ]),
                      ]),
                    ],
                  ]
                }),
              ],
              2
            ),
          ]),
        ],
        1
      ),
      _c(
        "span",
        { staticClass: "footers", attrs: { slot: "footer" }, slot: "footer" },
        [
          _c("div", { staticClass: "cancel", on: { click: _vm.cancel } }, [
            _vm._v("取 消"),
          ]),
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticClass: "ok",
              on: {
                click: function ($event) {
                  return _vm.ok("form")
                },
              },
            },
            [_vm._v("确 定")]
          ),
        ]
      ),
      _c("add-follow-ups-dialog", {
        attrs: { dialogVisible: _vm.dialogVisible2 },
        on: { ok: _vm.ok2, cancel: _vm.cancel2 },
      }),
      _c("add-contact-dialog", {
        attrs: { dialogVisible: _vm.dialogVisible1 },
        on: { ok: _vm.ok1, cancel: _vm.cancel1 },
      }),
      _c("CommDialog", {
        attrs: {
          dialogVisible: _vm.dialogVisible3,
          dataColumn: _vm.commOptions,
          title: "客户区域",
          type: 11,
        },
        on: {
          ok: _vm.cancel3,
          cancel: _vm.cancel3,
          dataChange: _vm.dataChange,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }