<template>
  <el-dialog
    v-drag
    :title="editData.code ? '修改' : '审核'"
    :visible.sync="dialogVisible"
    width="1160px"
    top="1%"
    :show-close="true"
    :append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="cancel"
  >
    <div v-loading="loading" class="content">
      <el-form ref="form" class="form" :model="companyData" :rules="rules">
        <el-row class="row">
          <div class="panel">
            <div class="form-item w-200">
              <label class="lable">客户编码</label>
              <el-form-item prop="nameCH">
                <el-input
                  v-model="companyData.nameCH"
                  disabled
                  placeholder="请输入中文名"
                ></el-input>
              </el-form-item>
            </div>
            <div class="form-item w-420">
              <label class="lable">中文名称</label>
              <el-form-item prop="nameCH">
                <el-input
                  v-model="companyData.nameCH"
                  disabled
                  placeholder="请输入中文名"
                ></el-input>
              </el-form-item>
            </div>
            <div class="form-item w-420">
              <label class="lable">英文名称</label>
              <el-form-item prop="nameEN">
                <el-input
                  v-model="companyData.nameEN"
                  :disabled="!!editData.code"
                  placeholder="请输入英文名"
                ></el-input>
              </el-form-item>
            </div>
          </div>
        </el-row>
        <el-row>
          <div class="panel">
            <div class="form-item w-200">
              <label class="lable">地址</label>
              <el-form-item prop="addressStr">
                <el-input
                  v-model="companyData.addressStr"
                  disabled
                  placeholder="请输入地址"
                ></el-input>
              </el-form-item>
            </div>
            <div class="form-item w-420">
              <label class="lable">详细地址</label>
              <el-form-item prop="nameCH">
                <el-input
                  v-model="companyData.address"
                  disabled
                  placeholder="请输入详细地址"
                ></el-input>
              </el-form-item>
            </div>
            <div class="form-item w-200">
              <label class="lable"><span class="red">* </span>代码</label>
              <el-form-item prop="code">
                <el-input
                  v-model="companyData.code"
                  placeholder="请输入代码(四-五位唯一)"
                ></el-input>
              </el-form-item>
            </div>
            <div class="form-item w-200">
              <label class="lable"><span class="red">* </span>简称</label>
              <el-form-item prop="wbName">
                <el-input
                  v-model="companyData.wbName"
                  placeholder="请输入简称"
                ></el-input>
              </el-form-item>
            </div>
          </div>
        </el-row>
        <el-row>
          <div class="panel">
            <div class="form-item w-200">
              <label class="lable"><span class="red">* </span>状态</label>
              <el-form-item prop="isActived">
                <el-select v-model="companyData.isActived" placeholder="请选择">
                  <el-option
                    v-for="item in activeOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="form-item w-200">
              <label class="lable"><span class="red">* </span>销售员</label>
              <el-form-item prop="saleId">
                <el-select
                  v-model="companyData.saleId"
                  placeholder="请选择销售员"
                >
                  <el-option
                    v-for="item in userOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="form-item w-200">
              <label class="lable"><span class="red">* </span>预警邮箱</label>
              <el-form-item prop="noticeEmail">
                <el-input
                  v-model="companyData.noticeEmail"
                  placeholder="请输入预警邮箱"
                ></el-input>
              </el-form-item>
            </div>
            <div class="form-item w-200">
              <label class="lable"><span class="red">* </span>结算币种</label>
              <el-form-item prop="currency">
                <el-select
                  v-model="companyData.currency"
                  placeholder="请选择"
                  :disabled="!!editData.code"
                >
                  <el-option
                    v-for="item in currencyData"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="form-item w-200">
              <label class="lable"><span class="red">* </span>签订合同</label>
              <el-form-item prop="bussinessFlag">
                <el-select
                  v-model="companyData.bussinessFlag"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in bussinessFlagOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
          </div>
        </el-row>
        <el-row>
          <div class="panel">
            <div class="form-item" style="width: 100%">
              <label class="lable"><span class="red">* </span>所属区域</label>
              <el-form-item prop="areaValue">
                <el-select
                  v-model="companyData.areaValue"
                  style="width: 460px"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in commOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
                <span class="add" @click="addComm">管理区域</span>
              </el-form-item>
            </div>
            <div class="form-item" style="width: 100%">
              <label class="lable">备注</label>
              <el-form-item prop="remark">
                <el-input
                  v-model="companyData.remark"
                  placeholder="请输入备注"
                ></el-input>
              </el-form-item>
            </div></div
          >
        </el-row>
        <el-row>
          <div class="panel">
            <div class="form-item" style="width: 100%">
              <label class="lable">T6后台地址</label>
              <el-form-item prop="areaValue">
                <el-input
                  v-model="companyData.t6Url"
                  style="width: 530px;"
                  placeholder="请输入T6后台地址"
                ></el-input>
              </el-form-item>
            </div>
            <div class="form-item" style="width: 100%">
              <label class="lable">T6密钥</label>
              <el-form-item prop="remark">
                <el-input
                  v-model="companyData.t6Token"
                  style="width: 530px;"
                  placeholder="请输入T6密钥"
                ></el-input>
              </el-form-item>
            </div></div
          >
        </el-row>
      </el-form>
      <div class="tab">
        <div class="line"></div>
        <BaseTab
          style="z-index: 1"
          :tabItems="tabItems"
          :propWidth="490"
          :active="active"
          @tabChange="tabChange"
        ></BaseTab>
      </div>
      <div class="add-item-content">
        <div class="add-item" @click="add">
          <i class="el-icon-circle-plus-outline" />
          添加
        </div>
      </div>
      <div class="baseTable">
        <ul v-show="active === 0">
          <li class="header">
            <div class="cell"><span class="red">* </span>中文名</div>
            <div class="cell">英文名</div>
            <div class="cell"><span class="red">* </span>联系人类型</div>
            <div class="cell"><span class="red">* </span>手机</div>
            <div class="cell"><span class="red">* </span>邮箱</div>
            <div class="cell">固定电话</div>
            <div class="cell">操作</div>
          </li>
        </ul>
        <ul v-show="active === 0" class="tableBody">
          <template v-for="(item, $index) in contactList">
            <template>
              <li :key="$index" class="row">
                <div class="cell" :title="item.nameCH">{{ item.nameCH }}</div>
                <div class="cell" :title="item.nameEN">{{ item.nameEN }}</div>
                <div class="cell">{{ item.type | formatterType }}</div>
                <div class="cell" :title="item.phone">{{ item.phone }}</div>
                <div class="cell" :title="item.email">{{ item.email }}</div>
                <div class="cell" :title="item.tel">{{ item.tel }}</div>
                <div class="cell">
                  <i class="el-icon-delete" title="删除" @click="removeContactList($index)" />
                </div>
              </li>
            </template>
          </template>
        </ul>
        <ul v-show="active === 1">
          <li class="header">
            <div class="cell1"><span class="red">* </span>跟进人类型</div>
            <div class="cell1"><span class="red">* </span>用户</div>
            <div class="cell1"><span class="red">* </span>中文名</div>
          </li>
        </ul>
        <ul v-show="active === 1" class="tableBody">
          <template v-for="(item, $index) in followUpsList">
            <template>
              <li :key="$index" class="row">
                <div class="cell1">{{ item.type | formatterType }}</div>
                <div class="cell1">{{ item.nameCH }}</div>
                <div class="cell1">
                  <i class="el-icon-delete" title="删除" @click="removeFollowUpsList($index)" />
                </div>
              </li>
            </template>
          </template>
        </ul>
      </div>
    </div>
    <span slot="footer" class="footers">
      <div class="cancel" @click="cancel">取 消</div>
      <div v-loading="loading" class="ok" @click="ok('form')">确 定</div>
    </span>
    <add-follow-ups-dialog
      :dialogVisible="dialogVisible2"
      @ok="ok2"
      @cancel="cancel2"
    ></add-follow-ups-dialog>
    <add-contact-dialog
      :dialogVisible="dialogVisible1"
      @ok="ok1"
      @cancel="cancel1"
    ></add-contact-dialog>
    <CommDialog
      :dialogVisible="dialogVisible3"
      :dataColumn="commOptions"
      title="客户区域"
      :type="11"
      @ok="cancel3"
      @cancel="cancel3"
      @dataChange="dataChange"
    ></CommDialog>
  </el-dialog>
</template>
<script>
const BaseTab = () => import('@/components/tab/BaseTab')
import { mapGetters } from 'vuex'
import AddContactDialog from './AddContactDialog.vue'
import AddFollowUpsDialog from './AddFollowUpsDialog.vue'
import {
  getSysParams,
  approve,
  updateCompany
} from '@/api/system'
import CommDialog from '@/components/dialog/CommDialog.vue'
import { isValidEmail } from '@/utils/validate'

export default {
  components: { AddContactDialog, AddFollowUpsDialog, CommDialog, BaseTab },
  filters: {
    formatterType(val) {
      let str = ''
      switch (val) {
        case 0:
          str = '操作'
          break
        case 1:
          str = '财务'
          break
        case 2:
          str = '客服'
          break
        case 3:
          str = '负责人'
          break
        case 4:
          str = '公司信息'
          break
      }
      return str
    }
  },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    editData: {
      type: Object,
      default: function() {
        return {}
      }
    },
    updateData: {
      type: Object,
      default: function() {
        return {}
      }
    },
    userOptions: {
      type: Array,
      default: function() {
        return []
      }
    }
  },
  data() {
    const validateEmail = (rule, value, callback) => {
      if (!isValidEmail(value)) {
        callback(new Error('请输入正确的邮箱'))
      } else {
        callback()
      }
    }
    const validateCode = (rule, value, callback) => {
      if (value.length !== 4 && value.length !== 5) {
        callback(new Error('请输入4、5字代码'))
      } else {
        callback()
      }
    }
    return {
      form: {},
      rules: {
        code: [
          { required: true, message: '请输入编码', trigger: 'blur' },
          { validator: validateCode, trigger: 'blur' }
        ],
        wbName: [{ required: true, message: '请输入简称', trigger: 'blur' }],
        isActived: [{ required: true, message: '请选择状态', trigger: 'blur' }],
        noticeEmail: [
          { required: true, message: '请输入通知邮箱', trigger: 'blur' },
          { validator: validateEmail, trigger: 'blur' }
        ],
        saleId: [{ required: true, message: '请选择销售员', trigger: 'blur' }],
        currency: [{ required: true, message: '请输入币制', trigger: 'blur' }],
        bussinessFlag: [
          { required: true, message: '请选择是否签订合同', trigger: 'blur' }
        ],
        areaValue: [
          { required: true, message: '请选择客户区域', trigger: 'blur' }
        ]
      },
      companyData: {},
      activeOptions: [
        {
          value: true,
          label: '在用'
        },
        {
          value: false,
          label: '停用'
        }
      ],
      bussinessFlagOptions: [
        {
          value: true,
          label: '已签'
        },
        {
          value: false,
          label: '未签'
        }
      ],
      tabItems: [
        {
          id: 0,
          name: '联系方式列表'
        },
        {
          id: 1,
          name: '跟进人列表'
        }
      ],
      active: 0,
      dialogVisible1: false,
      dialogVisible2: false,
      dialogVisible3: false,
      loading: false,
      contactList: [],
      followUpsList: [],
      currencyData: [],
      commData: []
    }
  },
  computed: {
    ...mapGetters(['addressDropOption']),
    commOptions: function() {
      const arr = []
      this.commData.map((item) => {
        arr.push({
          label: item.description,
          value: item.dbValue
        })
      })
      return arr
    }
  },
  watch: {
    dialogVisible: function(val) {
      if (val) {
        const country =
          this.addressDropOption &&
          this.addressDropOption.find(
            (a) => a.value === this.editData.countryId
          )
        const province =
          country &&
          country.children.find((a) => a.value === this.editData.provinceId)
        const city =
          province &&
          province.children.find((a) => a.value === this.editData.cityId)
        let addressStr = ''
        if (country && province && city) {
          addressStr = `${country.label}/${province.label}/${city.label}`
        }
        if (this.updateData.id) {
          this.companyData = {
            ...this.updateData
          }
          this.contactList = this.updateData.contacts
          this.followUpsList = this.updateData.followUps
        } else {
          this.companyData = {
            ...this.editData,
            addressStr,
            code: '',
            wbName: '',
            isActived: true,
            noticeEmail: '',
            saleId: '',
            currency: '',
            bussinessFlag: true,
            areaValue: ''
          }
          this.contactList = []
          this.followUpsList = []
        }
      }
    }
  },
  created() {
    this.getCommData()
  },
  methods: {
    tabChange(type) {
      this.active = type.id
    },
    cancel() {
      this.active = 0
      this.$emit('cancel')
        this.$refs['form'].resetFields()
    },
    ok(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let flag = false
          const arr = this.followUpsList.map(item => {
            return item.type
          })
          const optArr = [0, 1, 2]
          optArr.forEach(a => {
            if (arr.indexOf(a) === -1) {
              flag = true
            }
          })
          if (flag) {
            this.$message.warning('跟进人类型不完整或未填写')
            return false
          }
          this.loading = true
          if (this.updateData.id) {
            updateCompany({
              ...this.companyData,
              followUps: [...this.followUpsList],
              contacts: [...this.contactList]
            }).then((res) => {
              if (res.success) {
                this.$message.success(res.errorMessage)
                this.$emit('ok')
                this.loading = false
              } else {
                this.$message.error(res.errorMessage)
                this.loading = false
              }
            })
          } else {
            approve({
              ...this.companyData,
              followUps: [...this.followUpsList],
              contacts: [...this.contactList]
            }).then((res) => {
              if (res.success) {
                this.$message.success(res.errorMessage)
                this.$emit('ok')
                this.loading = false
              } else {
                this.$message.error(res.errorMessage)
                this.loading = false
                console.log(this.loading)
              }
            })
          }
        }
      })
    },
    cancel1() {
      this.dialogVisible1 = false
    },
    ok1(data) {
      this.contactList.push({
        ...data
      })
      this.dialogVisible1 = false
    },
    removeContactList(index) {
      this.contactList.splice(index, 1)
    },
    cancel2() {
      this.dialogVisible2 = false
    },
    ok2(data) {
      this.followUpsList.push({
        ...data
      })
      this.dialogVisible2 = false
    },
    addComm() {
      this.dialogVisible3 = true
    },
    cancel3() {
      this.dialogVisible3 = false
    },
    removeFollowUpsList(index) {
      this.followUpsList.splice(index, 1)
    },
    add() {
      if (this.active === 1) {
        this.dialogVisible2 = true
      } else {
        this.dialogVisible1 = true
      }
    },
    getCommData() {
      getSysParams({ type: 7 }).then((res) => {
        if (res.success) {
          const arr = []
          res.data &&
            res.data[0].value &&
            res.data[0].value.map((item) => {
              arr.push({
                value: item.dbValue,
                label: item.description
              })
            })
          this.currencyData = arr
        } else {
          this.$message.error(res.errorMessage)
        }
      })
      getSysParams({ type: 11 }).then((res) => {
        if (res.success) {
          const arr = []
          res.data &&
            res.data[0].value &&
            res.data[0].value.map((item) => {
              arr.push(item)
            })
          this.commData = arr
        } else {
          this.$message.error(res.errorMessage)
        }
      })
    },
    dataChange() {
      this.getCommData()
    }
  }
}
</script>
<style lang="less" scoped>
.content {
  width: calc(100% - 40px);
  height: auto;
  margin: 0 auto;
  text-align: left;
  .panel {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    &:first-child {
      margin-top: 0;
    }
    .add {
      width: 56px;
      height: 19px;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #2395f3;
      line-height: 40px;
      margin-left: 10px;
      cursor: pointer;
    }
  }
  .w-200 {
    width: 200px;
  }
  .w-420 {
    width: 420px;
  }
  .red {
    color: #ff7444;
  }
  .tab {
    width: 100%;
    height: 40px;
    margin: 30px auto;
    position: relative;
    .line {
      width: 100%;
      height: 1px;
      background: #eeeeee;
      position: absolute;
      top: 19px;
    }
  }
  .add-item-content {
    width: 100%;
    height: 40px;
    display: flex;
    flex-direction: row-reverse;
  }
  .add-item {
    width: 100px;
    height: 40px;
    background: #35ba83;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    line-height: 40px;
    text-align: center;
    cursor: pointer;
  }
  .baseTable {
    width: 100%;
    height: 240px;
    border: 1px solid #eeeeee;
    margin-top: 10px;
    border-radius: 4px 4px 4px 4px;
    box-sizing: border-box;
    overflow: hidden;
    ul {
      width: 100%;
    }
    ul li {
      width: 100%;
      height: 40px;
      display: flex;
      text-align: center;
    }
    .header {
      background: #f7f7f7;
      .cell {
        width: 14.286%;
        height: 40px;
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        line-height: 40px;
        border-right: 1px solid #eeeeee;
        border-bottom: 1px solid #eeeeee;
      }
      .cell1 {
        width: 33.34%;
        height: 40px;
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        line-height: 40px;
        border-right: 1px solid #eeeeee;
        border-bottom: 1px solid #eeeeee;
      }
    }
    .red {
      color: #ff7444;
    }
    .tableBody {
      width: 100%;
      height: 200px;
      overflow: auto;
      .cell {
        width: 14.286%;
        height: 40px;
        font-size: 14px;
        font-weight: 400;
        color: #666666;
        line-height: 40px;
        border-right: 1px solid #eeeeee;
        border-bottom: 1px solid #eeeeee;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .cell1 {
        width: 33.34%;
        height: 40px;
        font-size: 14px;
        font-weight: 400;
        color: #666666;

        line-height: 40px;
        border-right: 1px solid #eeeeee;
        border-bottom: 1px solid #eeeeee;
      }
      .el-icon-delete {
        cursor: pointer;
      }
    }
  }
}
.footers {
  display: flex;
  justify-content: center;
  .cancel {
    width: 120px;
    height: 40px;
    background: #eeeeee;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    font-size: 14px;
    font-weight: 400;
    color: #999999;
    line-height: 40px;
    text-align: center;
    cursor: pointer;
  }
  .ok {
    width: 120px;
    height: 40px;
    background: #2395f3;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    line-height: 40px;
    text-align: center;
    margin-left: 20px;
    cursor: pointer;
  }
}
:deep('.el-dialog') {
  margin: 0 auto;
}
</style>
