<template>
  <el-dialog
    v-drag
    title="拒绝原因"
    :visible.sync="dialogVisible"
    width="500px"
    :show-close="true"
    :append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="cancel"
  >
    <div class="content">
      <el-form ref="form" class="form" :model="form" :rules="rules">
        <el-form-item prop="refuseReason" label="拒绝原因">
          <el-input v-model="form.refuseReason" placeholder="请输入拒绝原因"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="footers">
      <div class="cancel" @click="cancel">取 消</div>
      <div class="ok" @click="ok('form')">确 定</div>
    </span>
  </el-dialog>
</template>

<script>
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      form: {
        refuseReason: ''
      },
      rules: {
        refuseReason: [{ required: true, message: '请输入拒绝原因', trigger: 'blur' }]
      }
    }
  },
  methods: {
    cancel() {
      this.$refs['form']?.resetFields()
      this.$emit('cancel')
    },
    ok(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$emit('ok', this.form)
          this.$refs[formName]?.resetFields()
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .el-dialog__body {
  padding-bottom: 0;
}
.footers {
  display: flex;
  justify-content: center;
  margin-top: 40px;
  .cancel {
    width: 120px;
    height: 40px;
    background: #eeeeee;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    font-size: 14px;
    font-weight: 400;
    color: #999999;
    line-height: 40px;
    text-align: center;
    cursor: pointer;
  }
  .ok {
    width: 120px;
    height: 40px;
    background: #2395f3;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    line-height: 40px;
    text-align: center;
    margin-left: 20px;
    cursor: pointer;
  }
}
</style>
