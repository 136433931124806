<template>
  <el-dialog
    v-drag
    title="新增"
    :visible.sync="dialogVisible"
    width="500px"
    :show-close="true"
    :append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="cancel"
  >
    <div class="content">
      <el-form ref="form" class="form" :model="form" :rules="rules">
        <el-form-item prop="type" label-width="100px" label="跟进人类型">
          <el-select
            v-model="form.type"
            style="width: 100%"
            placeholder="请选择"
          >
            <el-option
              v-for="item in typeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="userId" label-width="100px" label="跟进人">
          <el-select
            v-model="form.userId"
            style="width: 100%"
            placeholder="请选择"
            filterable
          >
            <el-option
              v-for="item in userTypeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="footers">
      <div class="cancel" @click="cancel">取 消</div>
      <div class="ok" @click="ok('form')">确 定</div>
    </span>
  </el-dialog>
</template>

<script>
import { getUserForType } from '@/api/system'
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      form: {
        type: 0,
        userId: ''
      },
      rules: {
        type: [{ required: true, message: '请选择类型', trigger: 'blur' }],
        userId: [{ required: true, message: '请选择更进人', trigger: 'blur' }]
      },
      typeOptions: [
        {
          value: 0,
          label: '操作'
        },
        {
          value: 1,
          label: '财务'
        },
        {
          value: 2,
          label: '客服'
        }
      ],
      userOptions: []
    }
  },
  computed: {
    userTypeOptions() {
      return this.userOptions.filter(user => {
        const arr = {
          0: [99, 100, 166, 666],
          1: [166, 666, 201, 202, 203, 204],
          2: [103, 104, 166, 666]
        }
        return arr[this.form.type].indexOf(user.type) > -1
      }).map(item => {
          return {
            value: item.id,
            label: item.nameCH
          }
      })
    }
  },
  created() {
    getUserForType({}).then((res) => {
      if (res.success) {
        // const arr = []
        // res.data.map((item) => {
        //   arr.push({
        //     value: item.id,
        //     label: item.nameCH
        //   })
        // })
        this.userOptions = res.data
      } else {
        this.$message.error(res.errorMessage)
      }
    })
  },
  methods: {
    cancel() {
      this.$refs['form'].resetFields()
      this.$emit('cancel')
    },
    ok(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
           const user = this.userOptions.find(a => a.id === this.form.userId)
          this.$emit('ok', {
            ...this.form,
            nameCH: user.nameCH
          })
          this.$refs['form'].resetFields()
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.footers {
  display: flex;
  justify-content: center;
  .cancel {
    width: 120px;
    height: 40px;
    background: #eeeeee;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    font-size: 14px;
    font-weight: 400;
    color: #999999;
    line-height: 40px;
    text-align: center;
    cursor: pointer;
  }
  .ok {
    width: 120px;
    height: 40px;
    background: #2395f3;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    line-height: 40px;
    text-align: center;
    margin-left: 20px;
    cursor: pointer;
  }
}
</style>
