var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      directives: [{ name: "drag", rawName: "v-drag" }],
      attrs: {
        title: "价格模板详情",
        visible: _vm.dialogVisible,
        width: "1160px",
        "show-close": true,
        "append-to-body": true,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "before-close": _vm.cancel,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "content" },
        [
          _c(
            "el-row",
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  staticClass: "form",
                  attrs: { model: _vm.form, "label-position": "top" },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "模板名称", prop: "name" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "200px" },
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.form.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "name", $$v)
                          },
                          expression: "form.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "提单类型", prop: "mblType" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "156px" },
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.form.mblType,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "mblType", $$v)
                          },
                          expression: "form.mblType",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "处理方式", prop: "pickUpType" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "156px" },
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.form.pickUpType,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "pickUpType", $$v)
                          },
                          expression: "form.pickUpType",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "机场/港口", prop: "transportName" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "156px" },
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.form.transportName,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "transportName", $$v)
                          },
                          expression: "form.transportName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "生效日期", prop: "effectiveDate" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "156px" },
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.form.effectiveDate,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "effectiveDate", $$v)
                          },
                          expression: "form.effectiveDate",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "失效日期", prop: "elapsedDate" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "156px" },
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.form.elapsedDate,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "elapsedDate", $$v)
                          },
                          expression: "form.elapsedDate",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c("BaseTable", {
                attrs: {
                  tableData: _vm.tableData,
                  height: "440",
                  isPager: false,
                  border: true,
                },
                scopedSlots: _vm._u([
                  {
                    key: "feeTypeId",
                    fn: function (row) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("formatterFeeType")(
                                row.scope.feeTypeId,
                                _vm.feeTypeOptions
                              )
                            ) +
                            " "
                        ),
                      ]
                    },
                  },
                  {
                    key: "feeNameId",
                    fn: function (row) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("formatterFeeName")(
                                row.scope.feeNameId,
                                row.scope.feeTypeId,
                                _vm.feeTypeOptions2
                              )
                            ) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }