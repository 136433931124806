<template>
  <el-dialog
    v-drag
    title="价格模板详情"
    :visible.sync="dialogVisible"
    width="1160px"
    :show-close="true"
    :append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="cancel"
  >
    <div class="content">
      <el-row>
        <el-form ref="form" class="form" :model="form" label-position="top">
          <el-form-item label="模板名称" prop="name">
            <el-input
              v-model="form.name"
              style="width: 200px"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item label="提单类型" prop="mblType">
            <el-input
              v-model="form.mblType"
              style="width: 156px"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item label="处理方式" prop="pickUpType">
            <el-input
              v-model="form.pickUpType"
              style="width: 156px"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item label="机场/港口" prop="transportName">
            <el-input
              v-model="form.transportName"
              style="width: 156px"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item label="生效日期" prop="effectiveDate">
            <el-input
              v-model="form.effectiveDate"
              style="width: 156px"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item label="失效日期" prop="elapsedDate">
            <el-input
              v-model="form.elapsedDate"
              style="width: 156px"
              disabled
            ></el-input>
          </el-form-item>
        </el-form>
      </el-row>
      <el-row>
        <BaseTable
          :tableData="tableData"
          height="440"
          :isPager="false"
          :border="true"
        >
          <template slot="feeTypeId" slot-scope="row">
            {{ row.scope.feeTypeId | formatterFeeType(feeTypeOptions) }}
          </template>
          <template slot="feeNameId" slot-scope="row">
            {{ row.scope.feeNameId | formatterFeeName(row.scope.feeTypeId, feeTypeOptions2) }}
          </template>
        </BaseTable>
      </el-row>
    </div>
  </el-dialog>
</template>

<script>
const BaseTable = () => import('@/components/table/BaseTable')
import {
  getMBLPriceTemplateDetail
} from '@/api/bill'
export default {
  components: { BaseTable },
  filters: {
    formatterFeeType(v, options) {
      const current = options.find((a) => a.value === v)
      return current.label
    },
    formatterFeeName(v, parentId, options) {
      const newId = parentId.toString()
      const feeNameOptions = options.filter(
        (a) => newId.indexOf(a.feeTypeId.toString()) > -1
      )
      const current = feeNameOptions.find((a) => a.value === v)
      return options.length ? current.name : ''
    }
  },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    rowData: {
      type: Object,
      default: () => {}
    },
    feeTypeOptions: {
      type: Array,
      default: () => []
    },
    feeTypeOptions2: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      form: {
        name: '',
        mblType: '',
        pickUpType: '',
        effectiveDate: '',
        elapsedDate: '',
        transportName: ''
      },
      tableData: {
        fileds: [
          {
            filed: 'feeTypeId',
            label: '费用大类',
            isCustom: true,
            resizable: true,
            width: 140
          },
          {
            filed: 'feeNameId',
            label: '费用细类',
            isCustom: true,
            resizable: true,
            width: 140
          },
          {
            filed: 'unit',
            label: '计算维度',
            resizable: true,
            width: 80,
            formatter: (row) => {
              let str
              switch (row.unit) {
                case 0:
                  str = '个'
                  break
                case 1:
                  str = '分单'
                  break
                case 2:
                  str = '柜子'
                  break
                case 3:
                  str = '20GP'
                  break
                case 4:
                  str = '40HQ'
                  break
                case 5:
                  str = '45HQ'
                  break
                default:
                  str = ''
                  break
              }
              return str
            }
          },
          {
            filed: 'unitPrice',
            label: '单价(USD)',
            resizable: true,
            width: 110
          },
          {
            filed: 'autoTrigger',
            label: '自动触发',
            resizable: true,
            width: 80,
            formatter: (row) => row.autoTrigger ? '是' : '否'
          },
          {
            filed: 'remarks',
            label: '备注',
            resizable: true,
            width: 180
          },
          {
            filed: 'description',
            label: '费用介绍',
            resizable: true,
            width: 180,
            formatter: (row) => (row.description ? row.description : '')
          },
          {
            filed: 'name',
            label: '报价模板',
            resizable: true,
            width: 168
          }
        ],
        columns: []
      }
    }
  },
  watch: {
    dialogVisible(val) {
      if (val) {
        this.getList()
        const { name, mblTypeName, pickUpTypeName, transportName } = this.rowData
        this.form.name = name
        this.form.mblType = mblTypeName
        this.form.pickUpType = pickUpTypeName
        this.form.transportName = transportName
      }
    }
  },
  methods: {
    cancel() {
      this.tableData.columns = []
      this.$emit('cancel')
    },
    async getList() {
      try {
        const res = await getMBLPriceTemplateDetail({ templateId: this.rowData.mblPriceTemplateId })
        if (res.success) {
          this.tableData.columns = res.data.templateItems.map(a => {
            return {
              ...a,
              name: this.rowData.name
            }
          })
          const effectiveDate = res.data.effectiveDate
          const elapsedDate = res.data.elapsedDate
          this.form.effectiveDate = effectiveDate.slice(0, effectiveDate.length - 9)
          this.form.elapsedDate = elapsedDate.slice(0, elapsedDate.length - 9)
        } else {
          this.$message.error(res.errorMessage)
        }
      } catch (error) {
        console.error(error)
      }
    }
  }
}
</script>

<style lang="less" scoped>
.content {
  width: calc(100% - 40px);
  margin: 0 auto;
  .form {
    display: flex;
    & .el-form-item + .el-form-item {
      margin-left: 20px;
    }
  }
}
</style>
