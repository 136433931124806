<template>
  <div class="view-contanier">
    <div class="opt">
      <div class="tab">
        <span
          v-permissson="'DSH'"
          class="tabItem"
          :class="{ active: active === 1 }"
          @click="changeType(1)"
        >待审核</span
        >
        <span
          v-permissson="'YSH'"
          class="tabItem"
          :class="{ active: active === 2 }"
          @click="changeType(2)"
        >已审核</span
        >
      </div>
      <BaseFilter :isActive="isActive" :width="466" @sendFilter="sendFilter" @reset="reset">
        <div class="filter">
          <el-form ref="form" :model="filterList" label-width="70px">
            <el-form-item label="搜索">
              <el-input
                v-model="filterList.search"
                placeholder="请输入代码/中文名称/英文名称/简称"
                style="width: 320px;"
              ></el-input>
            </el-form-item>
            <el-form-item label="所属区域">
              <el-select
                v-model="filterList.areaValue"
                style="width: 320px;"
                placeholder="请选择所属区域"
              >
                <el-option
                  v-for="item in commOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="销售员">
              <el-select
                v-model="filterList.saleId"
                style="width: 320px;"
                placeholder="请选择销售员"
              >
                <el-option
                  v-for="item in userOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="状态">
              <el-select
                v-model="filterList.isActived"
                style="width: 320px;"
                placeholder="请选择状态"
              >
                <el-option
                  v-for="item in activeOption"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </div>
      </BaseFilter>
    </div>
    <div v-show="active === 1" class="data">
      <!-- :key="active" -->
      <BaseTable
        v-if="height !== 0"
        :height="height"
        :tableData="tableData"
        :pageIndex="pagerConfig.pageIndex"
        :pageSize="pagerConfig.pageSize"
        :loading="loading"
        @handlePageChange="handlePageChange"
      >
        <template slot="isActived" slot-scope="row">
          <div
            class="circle"
            :style="{
              'background-color': row.scope.isActived ? ' #35BA83' : '#FE453D',
            }"
          ></div>
          {{ row.scope.isActived ? "使用中" : "未使用" }}
        </template>
        <template slot="yy" slot-scope="row">
          <img src="@/assets/images/system/license.png" class="license" @click="viewImg(row.scope, 0)" />
        </template>
        <template slot="userRole" slot-scope="row">
          <div v-for="(item, $index) in row.scope.userRole" :key="$index">
            <el-tag>{{ item.key }}</el-tag>
          </div>
        </template>
        <template slot="opt" slot-scope="row">
          <div class="table-opt">
            <el-button
              v-permissson="'pass'"
              type="text"
              @click="approved(row.scope)"
            >通过</el-button
            >
            <el-button
              v-permissson="'refuse'"
              type="text"
              @click="refuse(row.scope)"
            >拒绝</el-button
            >
          </div>
        </template>
      </BaseTable>
    </div>
    <div v-show="active === 2" class="data">
      <BaseTable
        v-if="height !== 0"
        :height="height"
        :tableData="tableData1"
        :pageIndex="pagerConfig.pageIndex"
        :pageSize="pagerConfig.pageSize"
        :loading="loading"
        @handlePageChange="handlePageChange"
      >
        <template slot="isActived" slot-scope="row">
          <div
            class="circle"
            :style="{
              'background-color': row.scope.isActived ? ' #35BA83' : '#FE453D',
            }"
          ></div>
          {{ row.scope.isActived ? "使用中" : "未使用" }}
        </template>
        <template slot="yy" slot-scope="row">
          <img src="@/assets/images/system/license.png" class="license" @click="viewImg(row.scope, 1)" />
        </template>
        <template slot="address" slot-scope="row">
          <el-tooltip
            class="item"
            effect="dark"
            :tabindex="2000"
            placement="bottom"
          >
            <div slot="content">
              {{
                `${row.scope.countryId}/${row.scope.provinceId}/${row.scope.cityId}`
                  | formatterAddress
              }}{{ row.scope.address }}
            </div>
            <svg-icon iconClass="address" className="icon" />
          </el-tooltip>
        </template>
        <template slot="remark" slot-scope="row">
          <el-tooltip
            class="item"
            effect="dark"
            :tabindex="2000"
            :content="row.scope.remark"
            placement="bottom"
          >
            <svg-icon iconClass="remark" className="icon" />
          </el-tooltip>
        </template>
        <template slot="opt" slot-scope="row">
          <div class="table-opt">
            <el-switch
              v-model="row.scope.isActived"
              v-permissson="'able'"
              active-color="#2395F3"
              inactive-color="#CDCDCD"
              @change="
                (value) => {
                  changeStatus(value, row.scope);
                }
              "
            >
            </el-switch
            ><span
              v-permissson="'able'"
              class="status"
              :style="{
                color: row.scope.isActived ? '#2395F3' : '#999999',
              }"
            >{{ row.scope.isActived ? "启用" : "停用" }}</span
            >
            <el-button
              v-permissson="'update'"
              type="text"
              @click="updateRow(row.scope)"
            >修改</el-button
            >
            <el-button
              v-permissson="'priceSetting'"
              type="text"
              @click="billAccount(row.scope)"
            >价格设置</el-button
            >
          </div>
        </template>
      </BaseTable>
    </div>
    <approved-company-dialog
      :dialogVisible="dialogFormVisible"
      :editData="editData"
      :userOptions="userOptions"
      :updateData="updateData"
      @ok="ok"
      @cancel="cancel"
    >
    </approved-company-dialog>
    <bill-account-dialog
      :dialogVisible="dialogFormVisible1"
      :companyId="editData.id"
      @ok="ok2"
      @cancel="cancel2">
    </bill-account-dialog>
    <RefuseReasonDialog
      :dialogVisible="dialogFormVisible2"
      @ok="ok3"
      @cancel="dialogFormVisible2 = false">
    </RefuseReasonDialog>
    <el-dialog
      v-drag
      title="查看营业执照"
      :visible.sync="dialogVisible2"
      :append-to-body="true"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      :before-close="() => dialogVisible2 = false"
      width="858px"
      center
    >
      <div class="imgbox">
        <img :src="`/backEnd/api/${config.supplyCode}/file/certification?companyId=${companyId}`" />
      </div>
    </el-dialog>
  </div>
</template>

<script>
let that
const BaseTable = () => import('@/components/table/BaseTable')
import {
  getNotApprovedCompanyPages,
  getCompanyPages,
  disableCompany,
  enableCompany,
  getCompanyDetail,
  getUserForType,
  getSysParams,
  reject
  // certification
} from '@/api/system'
import { mapGetters } from 'vuex'
import { permissions } from '@/utils/common'
import ApprovedCompanyDialog from './components/ApprovedCompanyDialog.vue'
import BillAccountDialog from './components/BillAccountDialog.vue'
import RefuseReasonDialog from './components/RefuseReasonDialog.vue'
import config from '@/utils/config'
const BaseFilter = () => import('@/components/filter/BaseFilter')
import { myMixins } from '@/mixins'
export default {
  name: 'Company',
  components: { BaseTable, ApprovedCompanyDialog, BaseFilter, BillAccountDialog, RefuseReasonDialog },
  filters: {
    formatterAddress(val) {
      const arr = val.split('/')
      const country =
        that.addressDropOption &&
        that.addressDropOption.find((a) => a.value === parseInt(arr[0], 10))
      const province =
        country &&
        country.children.find((a) => a.value === parseInt(arr[1], 10))
      const city =
        province &&
        province.children.find((a) => a.value === parseInt(arr[2], 10))
      let addressStr = ''
      if (country && province && city) {
        addressStr = `${country.label}${province.label}${city.label}`
      }
      return addressStr
    }
  },
  mixins: [myMixins],
  data() {
    return {
      config,
      pagerConfig: {
        pageIndex: 1,
        pageSize: 20
      },
      loading: false,
      tableData: {
        fileds: [
          {
            filed: 'identityCode',
            label: '公司编码',
            width: '150px',
            fixed: 'left'
          },
          {
            filed: 'nameCH',
            label: '中文名称',
            width: '180px'
          },
          {
            filed: 'nameEN',
            label: '英文名称',
            width: '180px'
          },
          {
            filed: 'countryId',
            label: '国家',
            width: '100px',
            formatter: (row) => {
              const item =
                this.addressDropOption &&
                this.addressDropOption.find((a) => a.value === row.countryId)
              return item && item.label
            }
          },
          {
            filed: 'provinceId',
            label: '省/州',
            width: '120px',
            formatter: (row) => {
              const country =
                this.addressDropOption &&
                this.addressDropOption.find((a) => a.value === row.countryId)
              const province =
                country &&
                country.children.find((a) => a.value === row.provinceId)
              return province && province.label
            }
          },
          {
            filed: 'cityId',
            label: '城市',
            width: '120px',
            formatter: (row) => {
              const country =
                this.addressDropOption &&
                this.addressDropOption.find((a) => a.value === row.countryId)
              const province =
                country &&
                country.children.find((a) => a.value === row.provinceId)
              const city =
                province &&
                province.children.find((a) => a.value === row.cityId)
              return city && city.label
            }
          },
          {
            filed: 'address',
            label: '详细地址',
            width: '360px'
          },
          {
            filed: 'yy',
            label: '营业执照',
            width: '80',
            isCustom: true
          },
          {
            filed: 'manager',
            label: '联系人',
            width: '100px'
          },
          {
            filed: 'phone',
            label: '联系电话',
            width: '160px'
          },
          {
            filed: 'email',
            label: '邮箱',
            width: '160px'
          },
          {
            filed: 'createdOn',
            label: '申请时间',
            width: '160px',
            formatter: (row) =>
              row.createdOn ? row.createdOn.slice(0, row.createdOn.length - 3) : ''
          },
          {
            filed: 'opt',
            label: '操作',
            width: '120px',
            fixed: 'right',
            isCustom: true
          }
        ],
        columns: [],
        total: 0
      },
      tableData1: {
        fileds: [
          {
            filed: 'code',
            label: '代码',
            width: '80px',
            fixed: 'left'
          },
          {
            filed: 'identityCode',
            label: '客户编码',
            width: '160px',
            fixed: 'left'
          },
          {
            filed: 'nameCH',
            label: '中文名称',
            width: '180px'
          },
          {
            filed: 'nameEN',
            label: '英文名称',
            width: '180px'
          },
          {
            filed: 'wbName',
            label: '简称',
            width: '90px'
          },
          {
            filed: 'isActived',
            label: '状态',
            width: '100px',
            isCustom: true
          },
          {
            filed: 'noticeEmail',
            label: '预警邮箱',
            width: '160px'
          },
          {
            filed: 'yy',
            label: '营业执照',
            width: '80',
            isCustom: true
          },
          {
            filed: 'address',
            label: '地址',
            width: '60px',
            isCustom: true
          },
          {
            filed: 'remark',
            label: '备注',
            width: '60px',
            isCustom: true
          },
          {
            filed: 'saleNameCH',
            label: '销售员',
            width: '80px'
          },
          {
            filed: 'currency',
            label: '结算币种',
            width: '80px'
          },
          {
            filed: 'bussinessFlag',
            label: '签订合同',
            width: '80px',
            formatter: (row) => {
              return row.bussinessFlag ? '是' : '否'
            }
          },
          {
            filed: 'createdOn',
            label: '创建时间',
            width: '160px',
            formatter: (row) =>
              row.createdOn ? row.createdOn.slice(0, row.createdOn.length - 3) : ''
          },
          {
            filed: 'createdName',
            label: '创建人',
            width: '80px'
          },
          {
            filed: 'updatedOn',
            label: '更新时间',
            width: '160px',
            formatter: (row) =>
              row.updatedOn ? row.updatedOn.slice(0, row.updatedOn.length - 3) : ''
          },
          {
            filed: 'updatedName',
            label: '更新人',
            width: '80px'
          },
          {
            filed: 'areaValue',
            label: '所属区域',
            width: '80px',
            formatter: (row) => {
              return this.commOptions.find(a => a.value === row.areaValue)?.label
            }
          },
          {
            filed: 'opt',
            label: '操作',
            width: '220px',
            fixed: 'right',
            isCustom: true
          }
        ],
        columns: [],
        total: 0
      },
      height: 0,
      dialogFormVisible: false,
      dialogFormVisible1: false,
      dialogFormVisible2: false,
      dialogVisible2: false,
      drawer: false,
      editData: {},
      active: 0,
      updateData: {},
      filterList: {
        search: '',
        isActived: '',
        areaValue: '',
        saleId: ''
      },
      isActive: false,
      activeOption: [
        {
          value: true,
          label: '使用中'
        },
        {
          value: false,
          label: '未使用'
        }
      ],
      userOptions: [],
      commData: [],
      companyId: 0
    }
  },
  computed: {
    ...mapGetters(['addressDropOption']),
    commOptions: function() {
      const arr = []
      this.commData.map((item) => {
        arr.push({
          label: item.description,
          value: item.dbValue
        })
      })
      return arr
    }
  },
  beforeCreate: function() {
    that = this
  },
  created() {
    const menuList = this.$store.getters.initRouter
    const menu = permissions(this.$route.meta.code, menuList)
    if (menu.children[0].children.length > 0) {
      this.active = 1
      this.getList(this.pagerConfig)
    } else {
      this.active = 2
      this.getCompanyList(this.pagerConfig)
    }
    this.getUserList()
    this.getCommData()
  },
  mounted() {
    if (this.active === 1) {
      this.height = document.querySelectorAll('.data')[0].offsetHeight - 43
    } else {
      this.height = document.querySelectorAll('.data')[1].offsetHeight - 43
    }
    if (!this.addressDropOption || this.addressDropOption.length === 0) {
      this.$store.dispatch('getAddress')
    }
  },
  methods: {
    getList(params) {
      this.loading = true
      try {
        params = {
          ...params,
          queryParam: {
            ...this.formatterFilter()
          }
        }
        getNotApprovedCompanyPages(params).then((res) => {
          if (res.success) {
            this.loading = false
            this.tableData.total = res.data.total
            this.tableData.columns = res.data.list
          } else {
            this.loading = false
            this.$message.error(res.errorMessage)
          }
        })
      } catch (error) {
        console.error(error)
      }
    },
    getCompanyList(params) {
      this.loading = true
      try {
        params = {
          ...params,
          queryParam: {
            ...this.formatterFilter()
          }
        }
        getCompanyPages(params).then((res) => {
          if (res.success) {
            this.loading = false
            this.tableData1.total = res.data.total
            this.tableData1.columns = res.data.list
          } else {
            this.loading = false
            this.$message.error(res.errorMessage)
          }
        })
      } catch (error) {
        this.loading = false
        console.error(error)
      }
    },
    getUserList() {
      getUserForType({
      }).then((res) => {
        if (res.success) {
          const arr = []
          res.data.filter(a => [102, 110, 111, 166].indexOf(a.type) > -1).map((item) => {
            arr.push({
              value: item.id,
              label: item.nameCH
            })
          })
          this.userOptions = arr
        } else {
          this.$message.error(res.errorMessage)
        }
      })
    },
    getCommData() {
      getSysParams({ type: 11 }).then((res) => {
        if (res.success) {
          const arr = []
          res.data &&
            res.data[0].value &&
            res.data[0].value.map((item) => {
              arr.push(item)
            })
          this.commData = arr
        } else {
          this.$message.error(res.errorMessage)
        }
      })
    },
    handlePageChange(pager) {
      this.pagerConfig = {
        ...this.pagerConfig,
        ...pager
      }
      const params = {
        ...this.pagerConfig,
        ...pager
      }
      this.active === 1 ? this.getList(params) : this.getCompanyList(params)
    },
    approved(row) {
      this.editData = row
      this.dialogFormVisible = true
    },
    async refuse(row) {
      this.editData = row
      this.dialogFormVisible2 = true
    },
    async ok3({ refuseReason }) {
      this.dialogFormVisible2 = false
      try {
        const params = {
          refuseReason,
          id: this.editData.id
        }
        const res = await reject(params)
        if (res.success) {
          this.getList(params)
          this.$message.success('拒绝成功')
        } else {
          this.$message.error(res.errorMessage)
        }
      } catch (error) {
        console.error(error)
      }
    },
    ok() {
      this.cancel()
      this.pagerConfig = {
        pageIndex: 1,
        pageSize: 20
      }
      const params = {
        pageIndex: 1,
        pageSize: 20
      }
      if (this.active === 1) {
        this.getList(params)
      } else {
        this.getCompanyList(params)
      }
      this.getCommData()
    },
    cancel() {
      this.dialogFormVisible = false
      this.editData = {}
      this.updateData = {}
    },
    changeStatus(value, row) {
      console.log(value, row)
      const obj = {
        id: row.id
      }
      if (value) {
        enableCompany(obj).then((res) => {
          if (res.success) {
            this.$message.success('启用成功')
          } else {
            this.$message.error(res.errorMessage)
          }
        })
      } else {
        disableCompany(obj).then((res) => {
          if (res.success) {
            this.$message.success('停用成功')
          } else {
            this.$message.error(res.errorMessage)
          }
        })
      }
    },
    changeType(type) {
      this.active = type
      this.pagerConfig = {
        pageIndex: 1,
        pageSize: this.pagerConfig.pageSize
      }
      const params = {
        pageIndex: 1,
        pageSize: this.pagerConfig.pageSize
      }
      this.reset()
      if (type === 1) {
        this.getList(params)
      } else {
        this.getCompanyList(params)
      }
    },
    updateRow(row) {
      this.editData = row
      getCompanyDetail({
        status: 1,
        id: row.id
      }).then((res) => {
        if (res.success) {
          this.dialogFormVisible = true
          this.updateData = res.data
        } else {
          this.$message.error(res.errorMessage)
        }
      })
    },
    sendFilter() {
      if (
        this.filterList.search !== '' ||
        this.filterList.isActived !== '' ||
        this.filterList.areaValue !== '' ||
        this.filterList.saleId !== ''
      ) {
        this.isActive = true
      } else {
        this.isActive = false
      }
      this.pagerConfig = {
        pageIndex: 1,
        pageSize: 20
      }
      const params = {
        pageIndex: 1,
        pageSize: 20
      }
      if (this.active === 1) {
        this.getList(params)
      } else {
        this.getCompanyList(params)
      }
    },
    reset() {
      this.filterList = {
        search: '',
        isActived: '',
        areaValue: '',
        saleId: ''
      }
      this.isActive = false
    },
    formatterFilter() {
      return {
        search:
          this.filterList.search !== '' ? this.filterList.search : undefined,
        isActived:
          this.filterList.isActived !== ''
            ? this.filterList.isActived
            : undefined,
        areaValue:
          this.filterList.areaValue !== ''
            ? this.filterList.areaValue
            : undefined,
        saleId:
          this.filterList.saleId !== '' ? this.filterList.saleId : undefined
      }
    },
    ok2() {
      this.cancel2()
    },
    cancel2() {
      this.editData = {}
      this.dialogFormVisible1 = false
    },
    billAccount(row) {
      this.editData = row
      this.dialogFormVisible1 = true
    },
    viewImg(row, type) {
      const id = type ? row.id : row.companyId
      this.companyId = id
      this.dialogVisible2 = true
    }
  }
}
</script>

<style lang="less" scoped>
.imgbox {
  width: 810px;
  height: 540px;
  margin: auto;
  display: flex;
  justify-content: center;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
.view-contanier {
  width: 100%;
  padding: 0 10px;
  height: 100%;
  background: #fff;
  box-sizing: border-box;
  overflow: auto;
  .opt {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .tabItem {
      padding: 20px;
      width: 42px;
      height: 19px;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #999999;
      line-height: 20px;
      cursor: pointer;
    }
    .active {
      color: #2395f3;
    }
  }
  .icon {
    font-size: 20px;
    vertical-align: text-top;
  }
  .data {
    width: 100%;
    height: calc(100% - 50px);
    & .fTable {
      border-top: 1px solid #ebeef5;
      box-sizing: border-box;
    }
  }
  .circle {
    width: 6px;
    height: 6px;
    opacity: 1;
    display: inline-block;
    border-radius: 50%;
    margin-right: 6px;
  }
  .table-opt:deep(.el-button) {
    padding: 0;
  }
  .status {
    width: 28px;
    height: 19px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #2395f3;
    line-height: 20px;
    margin-left: 5px;
    margin-right: 20px;
  }
  .icon {
    font-size: 24px;
  }
  .license {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
}
</style>
