<template>
  <el-dialog
    v-drag
    :title="editData.id ? '修改' : '新增'"
    :visible.sync="dialogVisible"
    width="800px"
    :show-close="true"
    :append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="cancel"
  >
    <div class="content">
      <div class="tab">
        <BaseTab
          style="z-index: 1"
          :tabItems="tabItems"
          :propWidth="490"
          :active="active"
          @tabChange="tabChange"
        ></BaseTab>
      </div>
      <template v-if="!active">
        <el-form ref="form" class="form" :model="form" :rules="rules">
          <div class="form-item">
            <label class="lable"><span class="red">* </span>快递服务商</label>
            <el-form-item prop="expressSupplierId">
              <el-select
                v-model="form.expressSupplierId"
                :disabled="form.id ? true : false"
                style="width: 320px;"
                placeholder="请选择快递服务商"
                @change="supplierChange"
              >
                <el-option
                  v-for="item in expressSupplierOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="form-item">
            <label class="lable"><span class="red">* </span>服务类型</label>
            <el-form-item prop="expressSupplierServiceId">
              <el-select
                v-model="form.expressSupplierServiceId"
                :disabled="form.id ? true : false"
                style="width: 320px;"
                placeholder="请选择服务类型"
                @change="serviceChange"
              >
                <el-option
                  v-for="item in expressSupplierServiceIdOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="form-item">
            <label class="lable"><span class="red">* </span>账号</label>
            <el-form-item prop="expressAccountIds">
              <el-select
                v-model="form.expressAccountIds"
                :disabled="form.id ? true : disabled"
                style="width: 320px;"
                multiple
                placeholder="请选择账号"
              >
                <el-option
                  v-for="item in expressAccountOption"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="form-item">
            <label
              class="lable"
            ><span class="red">* </span>派送折扣
              <el-tooltip
                class="item"
                effect="dark"
                content="1.05=5%*价格表"
                placement="right"
              >
                <svg-icon style="color: #FE453D;" iconClass="question" className="icon" />
              </el-tooltip>
            </label>
            <el-form-item prop="deliveryRate">
              <el-input-number
                v-model="form.deliveryRate"
                style="width: 320px;"
                controls-position="right"
                :min="0.001"
                :max="100"
                :precision="3"
                :step="0.1"
              >
              </el-input-number>
            </el-form-item>
          </div>
        </el-form>
      </template>
      <template v-else>
        <el-form ref="form" class="form" :model="form" :rules="rules">
          <div class="form-item">
            <label class="lable"><span class="red">* </span>选择模板</label>
            <el-form-item prop="expressAccountTemplateId">
              <el-select
                v-model="form.expressAccountTemplateId"
                filterable
                style="width: 320px;"
                placeholder="请选择模板"
                @change="accountTemplateChange"
              >
                <el-option
                  v-for="item in expressAccountTemplateArr"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </div>
        </el-form>
        <BaseTable
          height="240"
          :tableData="tableData"
          :isPager="false"
          :border="true"
        ></BaseTable>
      </template>
    </div>
    <span slot="footer" class="footers">
      <div class="cancel" @click="cancel">取 消</div>
      <div v-loading="loading" class="ok" @click="ok('form')">确 定</div>
    </span>
  </el-dialog>
</template>

<script>
const BaseTable = () => import('@/components/table/BaseTable')
const BaseTab = () => import('@/components/tab/BaseTab')
import {
  getExpressSupplierList,
  addCEA,
  getExpressAccountPageList,
  getExpressAccountTempalteItem,
  expressAccountTemplateDrapdown,
  addCompanyExpressAccountTemplate
} from '@/api/system'
export default {
  components: {
    BaseTab,
    BaseTable
  },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    companyId: {
      type: [String, Number],
      default: ''
    },
    accountNumArr: {
      type: Array,
      default: () => []
    },
    editData: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      form: {
        expressSupplierId: '',
        expressSupplierServiceId: '',
        expressAccountIds: [],
        deliveryRate: '',
        expressAccountTemplateId: ''
      },
      rules: {
        expressSupplierId: [
          { required: true, message: '请选择快递服务商', trigger: 'change' }
        ],
        expressSupplierServiceId: [
          { required: true, message: '请选择服务类型', trigger: 'change' }
        ],
        expressAccountIds: [
          { required: true, message: '请选择账号', trigger: 'change' }
        ],
        deliveryRate: [
          { required: true, message: '请输入派送折扣', trigger: 'blur' }
        ],
        expressAccountTemplateId: [
          { required: true, message: '请选择模板', trigger: 'change' }
        ]
      },
      expressSupplierOptions: [],
      supplyService: [],
      expressAccountOption: [],
      tabItems: [
        {
          id: 0,
          name: '按账号新增'
        },
        {
          id: 1,
          name: '按模板新增'
        }
      ],
      active: 0,
      tableData: {
        fileds: [
          {
            filed: 'accountNumber',
            label: '账号',
            width: 200,
            resizable: true
          },
          {
            filed: 'serviceProviderName',
            label: '快递商',
            resizable: true
          },
          {
            filed: 'serviceTypeName',
            label: '服务类型',
            resizable: true
          },
          {
            filed: 'deliveryRate',
            label: '派送折扣',
            resizable: true
          }
        ],
        columns: []
      },
      expressAccountTemplateArr: [],
      loading: false
    }
  },
  computed: {
    expressSupplierServiceIdOptions: function() {
      const service = this.supplyService.find((item) => {
        return item.id === this.form.expressSupplierId
      })
      const arr =
        service &&
        service.serviceTypeList.map((item) => {
          return {
            label: item.name,
            value: item.id
          }
        })
      return arr || []
    },
    disabled: function() {
      if (this.form.expressSupplierId && this.form.expressSupplierServiceId) {
        return false
      } else {
        return true
      }
    }
  },
  watch: {
    dialogVisible: function(val) {
      if (val) {
        this.$refs['form']?.resetFields()
        this.getSupply()
        if (this.editData.id) {
          this.form = {
            ...this.editData,
            expressAccountIds: [this.editData.expressAccountId]
          }
        } else {
          this.$refs['form']?.resetFields()
          this.form = {
            expressSupplierId: '',
            expressSupplierServiceId: '',
            expressAccountIds: [],
            deliveryRate: ''
          }
        }
      }
    }
  },
  methods: {
    async getLists(tempalteId) {
      try {
        const res = await getExpressAccountTempalteItem({ tempalteId })
        if (res.success) {
          this.tableData.columns = res.data
        } else {
          this.$message.error(res.errorMessage)
        }
      } catch (error) {
        console.error(error)
      }
    },
    async getExpressAccountTemplate() {
      try {
        const res = await expressAccountTemplateDrapdown()
        if (res.success) {
          this.expressAccountTemplateArr = res.data
        } else {
          this.$message.error(res.errorMessage)
        }
      } catch (error) {
        console.error(error)
      }
    },
    cancel() {
      this.active = 0
      this.tableData.columns = []
      this.$emit('cancel')
    },
    ok(formName) {
      this.$refs[formName].validate(async(valid) => {
        if (valid) {
          this.loading = true
          try {
            const obj = {
              companyId: this.companyId,
              expressAccountIds: this.form.expressAccountIds,
              deliveryRate: this.form.deliveryRate
            }
            if (!this.active) {
              const res = await addCEA(obj)
              this.loading = false
              if (res.success) {
                this.$message.success('添加成功')
                this.$emit('ok')
              } else {
                this.$message.error(res.errorMessage)
              }
            } else {
              const params = {
                companyId: this.companyId,
                expressAccountTemplateId: this.form.expressAccountTemplateId
              }
              const res = await addCompanyExpressAccountTemplate(params)
              this.loading = false
              if (res.success) {
                this.$message.success('添加成功')
                this.$emit('ok')
              } else {
                this.$message.error(res.errorMessage)
              }
            }
          } catch (error) {
            this.loading = false
            console.error(error)
          }
        }
      })
    },
    async getSupply() {
      try {
        const res = await getExpressSupplierList()
        if (res.success) {
          const arr = []
          res.data &&
            res.data.map((item) => {
              arr.push({
                label: item.name,
                value: item.id
              })
            })
          this.supplyService = res.data
          this.expressSupplierOptions = arr
        } else {
          this.$message.error(res.errorMessage)
        }
      } catch (error) {
        console.error(error)
      }
    },
    supplierChange() {
      this.form.expressSupplierServiceId = ''
      this.form.expressAccountIds = []
    },
    async serviceChange(value) {
      this.form.expressAccountIds = []
      try {
        const res = await getExpressAccountPageList({
          pageIndex: 1,
          pageSize: 1000,
          queryParam: {
            expressSupplierId: this.form.expressSupplierId,
            expressSupplierServiceId: value
          }
        })
        const arr = []
        if (res.success) {
            res.data && res.data.list.forEach(item => {
              if (this.accountNumArr.indexOf(item.accountNumber) < 0) {
                arr.push({
                    value: item.id,
                    label: item.accountNumber
                })
              }
            })
        } else {
          this.$message.error(res.errorMessage)
        }
        this.expressAccountOption = arr
      } catch (error) {
        console.log(error)
      }
    },
    tabChange(type) {
      this.active = type.id
      type && this.getExpressAccountTemplate()
      this.$refs['form']?.resetFields()
    },
    accountTemplateChange(v) {
      this.getLists(v)
    }
  }
}
</script>

<style lang="less" scoped>
.content {
  width: calc(100% - 80px);
  margin: 20px auto 0;
}
.tab {
  position: absolute;
  top: 40px;
  left: 50%;
  transform: translateX(-50%);
}
.form {
  display: flex;
  width: 100%;
  height: auto;
  flex-wrap: wrap;
}
.form-item {
  width: calc(50% - 20px);
  position: relative;
  .lable {
    width: 100%;
    height: 19px;
    font-size: 14px;
    font-weight: 400;
    color: #666666;
    line-height: 20px;
    margin-bottom: 5px;
  }
  .red {
    color: rgba(255, 116, 68, 1);
  }
  &:nth-child(2n) {
    margin-left: 40px;
  }
}
.icon {
  font-size: 20px;
  margin-left: 5px;
  vertical-align: sub;
}
.footers {
  display: flex;
  justify-content: center;
  .cancel {
    width: 120px;
    height: 40px;
    background: #eeeeee;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    font-size: 14px;
    font-weight: 400;
    color: #999999;
    line-height: 40px;
    text-align: center;
    cursor: pointer;
  }
  .ok {
    width: 120px;
    height: 40px;
    background: #2395f3;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    line-height: 40px;
    text-align: center;
    margin-left: 20px;
    cursor: pointer;
  }
}
</style>
