var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      directives: [{ name: "drag", rawName: "v-drag" }],
      attrs: {
        title: "新增",
        visible: _vm.dialogVisible,
        width: "500px",
        "show-close": true,
        "append-to-body": true,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "before-close": _vm.cancel,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "content" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              staticClass: "form",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-width": "66px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { prop: "nameCH", label: "中文名" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入中文名" },
                    model: {
                      value: _vm.form.nameCH,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "nameCH", $$v)
                      },
                      expression: "form.nameCH",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "nameEN", label: "英文名" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入英文名" },
                    model: {
                      value: _vm.form.nameEN,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "nameEN", $$v)
                      },
                      expression: "form.nameEN",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "type", label: "类型" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.form.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "type", $$v)
                        },
                        expression: "form.type",
                      },
                    },
                    _vm._l(_vm.typeOptions, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "phone", label: "手机" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入手机" },
                    model: {
                      value: _vm.form.phone,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "phone", $$v)
                      },
                      expression: "form.phone",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "tel", label: "电话" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入电话" },
                    model: {
                      value: _vm.form.tel,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "tel", $$v)
                      },
                      expression: "form.tel",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "email", label: "邮箱" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入邮箱" },
                    model: {
                      value: _vm.form.email,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "email", $$v)
                      },
                      expression: "form.email",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        { staticClass: "footers", attrs: { slot: "footer" }, slot: "footer" },
        [
          _c("div", { staticClass: "cancel", on: { click: _vm.cancel } }, [
            _vm._v("取 消"),
          ]),
          _c(
            "div",
            {
              staticClass: "ok",
              on: {
                click: function ($event) {
                  return _vm.ok("form")
                },
              },
            },
            [_vm._v("确 定")]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }